import React, { useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Badge, Collapse, Space, Tabs, Typography } from 'antd';

import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import useForm, { FormContext } from '../../../hooks/useForm';
import FormWrapper from '../../FormWrapper';
import SaveButton from '../../buttons/SaveButton';
import CancelButton from '../../buttons/CancelButton';
import ProductsGrid from '../ProductsGrid';
import { PosTypes } from '../../poses/constants';

const EditGroupForm = ({ group, onSubmit, onCancel }) => {
  const { t } = useTranslation();
  const [activeKeys, setActiveKey] = useState(['name']);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().trim().required(t('errors.notEmpty')),
        nameEn: yup.string().required(t('errors.notEmpty')),
        nameDe: yup.string().required(t('errors.notEmpty')),
        code: yup.string().required(t('errors.notEmpty')),
      }),
    [t],
  );

  const form = useForm({
    initialValue: group,
    schema: validationSchema,
    onSubmit,
    onCancel,
  });

  const {
    value,
    handleSubmit,
    input,
    setFormValue,
    isDirty,
    select,
    errorMessages,
  } = form;

  const { price, priceWithoutFee, products, name } = value;

  useEffect(() => {
    setFormValue(group);
  }, [setFormValue, group]);

  useEffect(() => {
    if (
      errorMessages &&
      Object.keys(errorMessages).some((e) =>
        ['name', 'nameEn', 'nameDe'].includes(e),
      )
    ) {
      setActiveKey(['name']);
    }
  }, [errorMessages]);

  return (
    <FormContext.Provider value={form}>
      <form onSubmit={handleSubmit}>
        <FormWrapper>
          <FormWrapper.Single>
            <Typography.Paragraph style={{ marginBottom: 0 }}>
              {t('entity.offers.productGroup.name._')}
            </Typography.Paragraph>
            <Collapse
              activeKey={activeKeys}
              onChange={() => setActiveKey(activeKeys ? null : 'name')}
            >
              <CollapsePanel key="name" header={name}>
                <FormWrapper.Input
                  label={t('entity.offers.productGroup.name.ro')}
                  props={{ ...input('name'), disabled: value.deleted }}
                />
                <FormWrapper.Input
                  label={t('entity.offers.productGroup.name.en')}
                  props={{ ...input('nameEn'), disabled: value.deleted }}
                />
                <FormWrapper.Input
                  label={t('entity.offers.productGroup.name.de')}
                  props={{ ...input('nameDe'), disabled: value.deleted }}
                />
              </CollapsePanel>
            </Collapse>
            <FormWrapper.Input
              label={t('entity.offers.productGroup.code')}
              props={{ ...input('code'), disabled: value.deleted }}
            />
            <FormWrapper.Number
              label={t('entity.offers.productGroup.price')}
              props={{
                value: isDirty
                  ? products
                      .map((p) => p.discountPrice * p.quantity)
                      .reduce((a, b) => a + b, null)
                  : price,
                disabled: true,
              }}
            />

            <FormWrapper.Number
              label={t('entity.offers.productGroup.priceWithoutFee')}
              props={{
                value: isDirty
                  ? products
                      .map((p) => p.price * p.quantity)
                      .reduce((a, b) => a + b, null)
                  : priceWithoutFee,
                disabled: true,
              }}
            />

            <FormWrapper.Select
              label={t('entity.taxonomy.poses.type._')}
              props={{
                options: PosTypes.content,
                loading: PosTypes.loading,
                mode: 'multiple',
                allowClear: true,
                ...select('posTypes'),
                labelProp: (val) => t(`entity.taxonomy.poses.type.${val.id}`),
              }}
            />
          </FormWrapper.Single>
          <FormWrapper.Double>
            <Tabs>
              <Tabs.TabPane
                tab={
                  <>
                    {t('entity.offers.productGroup.products')}{' '}
                    <Badge count={products.length} />
                  </>
                }
                key="products"
              >
                <ProductsGrid showDiscountPrice limitPasses />
              </Tabs.TabPane>
            </Tabs>
          </FormWrapper.Double>
        </FormWrapper>

        <Space>
          <SaveButton />
          <CancelButton onCancel={onCancel} />
        </Space>
      </form>
    </FormContext.Provider>
  );
};

export default EditGroupForm;
