import React, { useMemo, useEffect } from 'react';

import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import moment from 'moment';
import useForm from '../../hooks/useForm';
import FormWrapper from '../FormWrapper';
import SaveButton from '../buttons/SaveButton';
import PageTitle from '../PageTitle';
import CancelButton from '../buttons/CancelButton';

const EditHolidayForm = ({ holiday, onSubmit, onCancel }) => {
  const { t } = useTranslation();
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required(t('errors.notEmpty')),
        holidayDate: yup.number().required(t('errors.notEmpty')),
      }),
    [t],
  );
  const {
    value,
    handleSubmit,
    input,
    custom,
    checkbox,
    setValue,
    setFormValue,
  } = useForm({
    initialValue: holiday,
    schema: validationSchema,
    onSubmit,
    onCancel,
  });

  useEffect(() => {
    setFormValue(holiday);
  }, [setFormValue, holiday]);

  const { deleted } = value;

  return (
    <>
      <form onSubmit={handleSubmit}>
        <PageTitle
          title={holiday.name || t('entity.taxonomy.holiday._singular')}
        />

        <FormWrapper>
          <FormWrapper.Single>
            <FormWrapper.Input
              label={t('entity.taxonomy.holiday.name')}
              props={{ ...input('name'), disabled: deleted }}
            />

            <FormWrapper.DateTimePicker
              label={t('entity.taxonomy.holiday.holidayDate')}
              props={{
                ...custom('holidayDate'),
                setValue: (name, val) =>
                  setValue(name, val?.format('YYYYMMDD')),
                getValue: () =>
                  value.holidayDate
                    ? moment(value.holidayDate, 'YYYYMMDD')
                    : null,
                hideTime: true,
                disabled: deleted,
              }}
            />

            <FormWrapper.Switch
              label={t('entity.taxonomy.holiday.recurring')}
              props={{ ...checkbox('recurring'), disabled: deleted }}
            />

            <Space style={{ paddingTop: '1rem' }}>
              {!deleted && <SaveButton />}
              <CancelButton onCancel={onCancel} />
            </Space>
          </FormWrapper.Single>
        </FormWrapper>
      </form>
    </>
  );
};
export default EditHolidayForm;
