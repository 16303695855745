import React from 'react';
import ReactDOM from 'react-dom';
import i18n from 'i18next';
import backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import App from './App';

import './index.scss';

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV !== 'production') {
  window._env_ = window._env_ || {};

  // eslint-disable-next-line no-undef
  window._env_.API_BACKEND_URL = process.env.REACT_APP_API_BACKEND_URL;
  window._env_.VERSION = process.env.REACT_APP_VERSION;
  window._env_.RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  window._env_.DISABLE_ReCAPTCHA =
    process.env.REACT_APP_DISABLE_ReCAPTCHA || 'false';
  window._env_.ENROLL_EXTERNAL_CARD =
    process.env.REACT_APP_ENROLL_EXTERNAL_CARD || 'false';
  window._env_.ENABLE_ERP = process.env.REACT_APP_ENABLE_ERP || 'false';
  window._env_.ENABLE_FINES = process.env.REACT_APP_ENABLE_FINES || 'false';
  window._env_.STOCKS_ENABLE = process.env.REACT_APP_STOCKS_ENABLE || 'false';
  window._env_.DYNAMIC_PRICE = process.env.REACT_APP_DYNAMIC_PRICE || 'false';
}

i18n
  .use(backend)
  .use(initReactI18next)
  .init({
    lng: 'ro',
    fallbackLng: 'ro',
    ns: ['translation'],
    defaultNS: 'translation',
    backend: {
      loadPath: '/locales/{{lng}}.json',
    },
    react: {
      useSuspense: false,
    },
  });

ReactDOM.render(<App />, document.getElementById('root'));
