import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { FormContext } from '../../hooks/useForm';
import FormWrapper from '../FormWrapper';
import Column from '../../helpers/columns';
import DeleteItemIcon from '../buttons/DeleteItemIcon';
import useDictionaries from '../../hooks/useDictionaries';
import { getProducts } from '../../services/offers/products';

const now = new Date();

const dictionaries = {
  products: () => getProducts({ criterias: { categoryName: 'PURSE' } }),
};

const RetailerPosLimitsList = () => {
  const form = useContext(FormContext);
  const [t] = useTranslation();

  const { value, setValue, custom, select } = form;

  const { routePrices } = value;

  const onAdd = useCallback(
    () => setValue('routePrices', [...routePrices, { key: Math.random() }]),
    [setValue, routePrices],
  );

  const onRemove = useCallback(
    async (record) =>
      setValue(
        'routePrices',
        routePrices.filter((routePrice) =>
          record.key
            ? routePrice.key !== record.key
            : routePrice.id !== record.id,
        ),
      ),
    [routePrices, setValue],
  );

  const [{ products }] = useDictionaries(dictionaries);

  const columns = useMemo(
    () => [
      {
        title: t('entity.offers.product._singular'),
        dataIndex: 'product',
        key: 'product',
        width: 200,
        render: (_, record, index) => (
          <FormWrapper.Select
            props={{
              disabled: record.disabled,
              size: 'small',
              options: products.content,
              loading: products.loading,
              ...select(`routePrices[${index}].productId`),
            }}
          />
        ),
      },
      {
        title: t('entity.taxonomy.route.dateStart'),
        dataIndex: 'dateStart',
        key: 'dateStart',
        width: 200,
        render: (_, record, index) => {
          return (
            <FormWrapper.DateTimePicker
              props={{
                disabled: record.disabled,
                ...custom(`routePrices[${index}].dateStart`),
                size: 'small',
                disabledDate: (date) => date.isSameOrBefore(now),
              }}
            />
          );
        },
      },

      Column.actions(
        t('table.actions'),
        (limit) =>
          !limit.disabled && (
            <DeleteItemIcon
              title={t('components.limits._delete')}
              item={limit}
              action={onRemove}
              reload={() => null}
            />
          ),
      ),
    ],
    [custom, onRemove, products.content, products.loading, select, t],
  );

  return (
    <>
      <Button type="primary" icon={<PlusOutlined />} onClick={onAdd}>
        {t('actions.add')}
      </Button>
      <Table
        rowKey={(record) => record.key || record.id}
        columns={columns}
        pagination={false}
        dataSource={routePrices}
        size="small"
      />
    </>
  );
};

export default RetailerPosLimitsList;
