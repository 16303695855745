import React, { useMemo } from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import Default from '../../../../layouts/Default';
import PageTitle from '../../../../components/PageTitle';
import useDatasource from '../../../../hooks/useDatasource';
import { findAll, remove } from '../../../../services/taxonomies/zones';
import Column from '../../../../helpers/columns';
import EditItemIcon from '../../../../components/buttons/EditItemIcon';
import DeleteItemIcon from '../../../../components/buttons/DeleteItemIcon';
import AddEntityButton from '../../../../components/buttons/AddEntityButton';

const ZonesList = () => {
  const { t } = useTranslation();

  const { pagination, content, handleChange, reload } = useDatasource(findAll);

  const columns = useMemo(
    () => [
      Column.text('id', t('entity.taxonomy.zones.id'), {
        filter: true,
      }),
      Column.text('name', t('entity.taxonomy.zones.name'), {
        filter: true,
      }),
      Column.text('description', t('entity.taxonomy.zones.description'), {
        filter: true,
      }),
      Column.bool('deleted', t('entity.taxonomy.zones.deleted._'), {
        width: 100,
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity.taxonomy.zones.deleted.true'),
          t('entity.taxonomy.zones.deleted.false'),
          t('table.filter.unknown'),
        ],
      }),
      Column.actions(t('table.actions'), (record) => (
        <>
          <EditItemIcon path={`/admin/taxonomies/zones/${record.id}`} />
          <DeleteItemIcon
            title={t('entity.taxonomy.zones.delete._', record)}
            message={t('entity.taxonomy.zones.delete.deleted', record)}
            item={record}
            disabled={record?.deleted}
            action={remove}
            reload={reload}
          />
        </>
      )),
    ],
    [reload, t],
  );

  return (
    <Default>
      <PageTitle title={t('entity.taxonomy.zones._plural')}>
        <AddEntityButton
          entity={t('entity.taxonomy.zones._singular')}
          path="/admin/taxonomies/zones/new"
        />
      </PageTitle>
      <Table
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ x: 700, y: 700 }}
      />
    </Default>
  );
};

export default ZonesList;
