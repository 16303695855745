import React, { useMemo } from 'react';
import * as yup from 'yup';

import { useTranslation } from 'react-i18next';
import { Space } from 'antd';
import useForm from '../../hooks/useForm';
import PageTitle from '../PageTitle';
import FormWrapper from '../FormWrapper';
import SaveButton from '../buttons/SaveButton';
import CancelButton from '../buttons/CancelButton';

const EditZoneForm = ({ zone, onSubmit, onCancel }) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().trim().required(t('errors.notEmpty')),
      }),
    [t],
  );

  const { value, input, handleSubmit } = useForm({
    initialValue: zone,
    schema: validationSchema,
    onSubmit,
    onCancel,
  });
  return (
    <>
      <form onSubmit={handleSubmit}>
        <PageTitle title={zone.name || t('entity.taxonomy.zones._singular')} />
        <FormWrapper>
          <FormWrapper.Single>
            <FormWrapper.Input
              label={t('entity.taxonomy.zones.name')}
              props={{
                ...input('name'),
                disabled: value.deleted,
              }}
            />
            <FormWrapper.Input
              label={t('entity.taxonomy.zones.description')}
              props={{
                ...input('description'),
                disabled: value.deleted,
              }}
            />
          </FormWrapper.Single>
        </FormWrapper>
        <Space>
          <SaveButton disabled={value.deleted} />
          <CancelButton onCancel={onCancel} />
        </Space>
      </form>
    </>
  );
};

export default EditZoneForm;
