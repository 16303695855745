import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { AuthContextWrapper, PrivateRoute } from './components/auth';

import {
  AccountsList,
  ArticleTypesList,
  Blacklist,
  BlacklistReasonsList,
  CardTemplatesList,
  CompaniesList,
  CountriesList,
  CurrenciesList,
  DocTypesList,
  EditArticleType,
  EditBlacklistReason,
  EditBurglary,
  EditCardTemplate,
  EditCompany,
  EditCurrency,
  EditDocType,
  EditEquipmentBrand,
  EditHoliday,
  EditInvoice,
  EditOffer,
  EditOfferType,
  EditOrder,
  EditPos,
  EditProduct,
  EditProductGroup,
  EditProductPriorities,
  EditRetail,
  EditRetailer,
  EditRole,
  EditRoute,
  EditRouteGroup,
  EditSchedule,
  EditSector,
  EditStation,
  EditStationType,
  EditTokenSubtype,
  EditTransportBrand,
  EditTransportCostCenter,
  EditTransportDepo,
  EditTransportType,
  EditTransportUnit,
  EditTVARate,
  EditUser,
  EditUserType,
  EditValidator,
  EditZone,
  EnrolledProducts,
  Enrollment,
  EquipmentBrandsList,
  HolidaysList,
  Home,
  Inspector,
  Inspectors,
  InstitutionsList,
  Login,
  MobileSummary,
  NotFound,
  Offers,
  OffersList,
  OfferTypesList,
  OrdersList,
  POS,
  PosesList,
  ProductGroupsList,
  ProductPrioritiesList,
  ProductsList,
  ResetPassword,
  Retail,
  RetailersList,
  RolesList,
  RouteGroupsList,
  RoutesList,
  SaleReport,
  SaleReportMobile,
  SaleReportOrders,
  SaleReportPortal,
  SaleReportTvm,
  SchedulesList,
  SectorsList,
  StationsList,
  StationTypesList,
  Taxonomies,
  TokenSubtypesList,
  TransportBrandsList,
  TransportCostCentersList,
  TransportDeposList,
  TransportTypesList,
  TransportUnitsList,
  TravelerValidations,
  TVARatesList,
  TVMDashboard,
  UsersList,
  UserTypesList,
  ValidatorsList,
  ViewAccount,
  ZonesList,
} from './pages';
import Reports from './pages/admin/Reports';
import EditInstitution from './pages/admin/taxonomies/institutions/EditInstitution';
import EditCountry from './pages/admin/taxonomies/countries/EditCountry';
import Travelers from './pages/reports/travelers/Travelers';
import Messages from './pages/messages/MessageList';
import EditMessage from './pages/messages/EditMessage';
import EmvDetails from './pages/reports/EmvDetails/EmvDetails';
import RetryInvoiceGeneration from './pages/reports/RetryInvoiceGeneration/RetryInvoiceGeneration';
import ValidatorsDashboard from './pages/validators/ValidatorsDashboard';
import CalendarsList from './pages/admin/offers/calendars/CalendarsList';
import EditCalendar from './pages/admin/offers/calendars/EditCalendar';
import SystemParametersList from './pages/admin/taxonomies/system-parameters/SystemParametersList';
import EditSystemParameter from './pages/admin/taxonomies/system-parameters/EditSystemParameter';

const App = () => (
  <AuthContextWrapper>
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/reset" component={ResetPassword} />
        <Route exact path="/reset/:token" component={ResetPassword} />
        <PrivateRoute exact path="/home" component={Home} />
        <PrivateRoute
          exact
          path="/admin/users"
          component={UsersList}
          permissions={[
            'SYS_SUPERUSER',
            'ADMINISTRATION_USERS_VIEW_PERMISSION',
            'ADMINISTRATION_USERS_PERMISSION',
          ]}
        />
        <PrivateRoute
          exact
          path="/admin/users/:id"
          component={EditUser}
          permissions={[
            'SYS_SUPERUSER',
            'ADMINISTRATION_USERS_VIEW_PERMISSION',
            'ADMINISTRATION_USERS_PERMISSION',
          ]}
        />
        <PrivateRoute
          exact
          path="/admin/roles"
          component={RolesList}
          permissions="SYS_SUPERUSER"
        />
        <PrivateRoute
          exact
          path="/admin/roles/:id"
          component={EditRole}
          permissions="SYS_SUPERUSER"
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies"
          component={Taxonomies}
          permissions={[
            'SYS_SUPERUSER',
            'TAXONOMIES_PERMISSION',
            'SYS_VALIDATOR_EDIT',
          ]}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/companies"
          component={CompaniesList}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/companies/:id"
          component={EditCompany}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/retailers"
          component={RetailersList}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/retailers/:id"
          component={EditRetailer}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/user-types"
          component={UserTypesList}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/user-types/:id"
          component={EditUserType}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/doc-types"
          component={DocTypesList}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/doc-types/:id"
          component={EditDocType}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/station-types"
          component={StationTypesList}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/station-types/:id"
          component={EditStationType}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/stations"
          component={StationsList}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/stations/:id"
          component={EditStation}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/route-groups"
          component={RouteGroupsList}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/route-groups/:id"
          component={EditRouteGroup}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/routes"
          component={RoutesList}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/routes/:id"
          component={EditRoute}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/holidays"
          component={HolidaysList}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/holidays/:id"
          component={EditHoliday}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/article-types"
          component={ArticleTypesList}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/article-types/:id"
          component={EditArticleType}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/offers"
          component={Offers}
          permissions={['SYS_SUPERUSER', 'OFFERS_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/offers/products"
          component={ProductsList}
          permissions={['SYS_SUPERUSER', 'OFFERS_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/offers/products/:id"
          component={EditProduct}
          permissions={['SYS_SUPERUSER', 'OFFERS_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/offers/products/:id/clone"
          component={EditProduct}
          permissions={['SYS_SUPERUSER', 'OFFERS_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/offers/calendars"
          component={CalendarsList}
          permissions={['SYS_SUPERUSER', 'OFFERS_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/offers/calendars/:id"
          component={EditCalendar}
          permissions={['SYS_SUPERUSER', 'OFFERS_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/offers/groups"
          component={ProductGroupsList}
          permissions={['SYS_SUPERUSER', 'OFFERS_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/offers/groups/:id"
          component={EditProductGroup}
          permissions={['SYS_SUPERUSER', 'OFFERS_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/offers/offers"
          component={OffersList}
          permissions={['SYS_SUPERUSER', 'OFFERS_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/offers/offers/:id"
          component={EditOffer}
          permissions={['SYS_SUPERUSER', 'OFFERS_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/offers/offer-types"
          component={OfferTypesList}
          permissions={['SYS_SUPERUSER', 'OFFERS_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/offers/offer-types/:id"
          component={EditOfferType}
          permissions={['SYS_SUPERUSER', 'OFFERS_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/offers/schedules"
          component={SchedulesList}
          permissions={['SYS_SUPERUSER', 'OFFERS_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/offers/schedules/:id"
          component={EditSchedule}
          permissions={['SYS_SUPERUSER', 'OFFERS_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/poses"
          component={PosesList}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/poses/:id"
          component={EditPos}
          permissions={[
            'SYS_SUPERUSER',
            'TAXONOMIES_PERMISSION',
            'HEAD_RETAILER',
          ]}
        />
        <PrivateRoute
          exact
          path="/retail"
          component={Retail}
          permissions={['SYS_SUPERUSER', 'HEAD_RETAILER']}
        />
        <PrivateRoute
          exact
          path="/retail/:id"
          component={EditRetail}
          permissions={['SYS_SUPERUSER', 'HEAD_RETAILER']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/tva-rates"
          component={TVARatesList}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/tva-rates/:id"
          component={EditTVARate}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/accounts"
          component={AccountsList}
          permissions={[
            'SYS_SUPERUSER',
            'ADMINISTRATION_ACCOUNTS_VIEW_PERMISSION',
            'ADMINISTRATION_ACCOUNTS_PERMISSION',
          ]}
        />
        <PrivateRoute
          exact
          path="/admin/accounts/:id"
          component={ViewAccount}
          permissions={[
            'SYS_SUPERUSER',
            'ADMINISTRATION_ACCOUNTS_VIEW_PERMISSION',
            'ADMINISTRATION_ACCOUNTS_PERMISSION',
          ]}
        />
        {/* <PrivateRoute
          exact
          path="/stock"
          component={Stock}
          permissions={['SYS_SUPERUSER']}
        /> */}
        {/* <PrivateRoute
          exact
          path="/stock/invoices/:id"
          component={EditInvoice}
          permissions={['SYS_SUPERUSER']}
        /> */}
        <PrivateRoute
          exact
          path="/admin/offers/priorities"
          component={ProductPrioritiesList}
          permissions={['SYS_SUPERUSER', 'OFFERS_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/offers/priorities/edit"
          component={EditProductPriorities}
          permissions={['SYS_SUPERUSER', 'OFFERS_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/transport/brands"
          component={TransportBrandsList}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/transport/brands/:id"
          component={EditTransportBrand}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/card/templates"
          component={CardTemplatesList}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/card/templates/:id"
          component={EditCardTemplate}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/transport/depos"
          component={TransportDeposList}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/transport/depos/:id"
          component={EditTransportDepo}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/transport/cost-centers"
          component={TransportCostCentersList}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/transport/cost-centers/:id"
          component={EditTransportCostCenter}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/transport/types"
          component={TransportTypesList}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/transport/types/:id"
          component={EditTransportType}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/token/subtypes"
          component={TokenSubtypesList}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/token/subtypes/:id"
          component={EditTokenSubtype}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/transport/units"
          component={TransportUnitsList}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/transport/units/:id"
          component={EditTransportUnit}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/equipment/brands"
          component={EquipmentBrandsList}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/equipment/brands/:id"
          component={EditEquipmentBrand}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/validators"
          component={ValidatorsList}
          permissions={[
            'SYS_SUPERUSER',
            'TAXONOMIES_PERMISSION',
            'SYS_VALIDATOR_EDIT',
          ]}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/validators/:id"
          component={EditValidator}
          permissions={[
            'SYS_SUPERUSER',
            'TAXONOMIES_PERMISSION',
            'SYS_VALIDATOR_EDIT',
            'TVM_DASHBOARD_PERMISSION',
          ]}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/sectors"
          component={SectorsList}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/sectors/:id"
          component={EditSector}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/currencies"
          component={CurrenciesList}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/currencies/:id"
          component={EditCurrency}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/zones"
          component={ZonesList}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/zones/:id"
          component={EditZone}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/enrollment"
          component={Enrollment}
          permissions={['SYS_SUPERUSER', 'ENROLLMENT_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/invoices/:invoiceType/:id"
          component={EditInvoice}
          permissions={[
            'SYS_SUPERUSER',
            'WAREHOUSE_PERMISSION',
            'ENROLLMENT_PERMISSION',
            'HEAD_RETAILER',
          ]}
        />
        <PrivateRoute
          exact
          path="/messages"
          component={Messages}
          permissions={['SYS_SUPERUSER']}
        />
        <PrivateRoute
          exact
          path="/messages/:id"
          component={EditMessage}
          permissions={['SYS_SUPERUSER']}
        />
        <PrivateRoute
          exact
          path="/inspectors"
          component={Inspectors}
          permissions={['SYS_SUPERUSER', 'HEAD_SECTOR_INSPECTOR']}
        />
        <PrivateRoute
          exact
          path="/inspectors/:id"
          component={Inspector}
          permissions={['SYS_SUPERUSER', 'HEAD_SECTOR_INSPECTOR']}
        />
        <PrivateRoute
          exact
          path="/reports/list"
          component={Reports}
          permissions={['SYS_SUPERUSER', 'REPORTS_LIST_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/reports/traveler-validations"
          component={TravelerValidations}
          permissions={[
            'SYS_SUPERUSER',
            'REPORTS_TRAVELER_VALIDATIONS_PERMISSION',
          ]}
        />
        <PrivateRoute
          exact
          path="/reports/emv-details"
          component={EmvDetails}
          permissions={['SYS_SUPERUSER', 'REPORTS_EMV_DETAILS_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/reports/traveles"
          component={Travelers}
          permissions={['SYS_SUPERUSER', 'REPORTS_TRAVELES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/reports/sales-report-pos"
          component={SaleReport}
          permissions={['SYS_SUPERUSER', 'REPORTS_SALES_POS_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/reports/retry-invoice-generation"
          component={RetryInvoiceGeneration}
          permissions={['SYS_SUPERUSER']}
        />
        <PrivateRoute
          exact
          path="/reports/sales-report-tvm"
          component={SaleReportTvm}
          permissions={['SYS_SUPERUSER', 'REPORTS_SALES_TVM_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/reports/enrolled-products"
          component={EnrolledProducts}
          permissions={[
            'SYS_SUPERUSER',
            'REPORTS_RETRY_ENROLLED_PRODUCTS_PERMISSION',
          ]}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/blacklist-reasons"
          component={BlacklistReasonsList}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/blacklist-reasons/:id"
          component={EditBlacklistReason}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/blacklist"
          component={Blacklist}
          permissions={['SYS_SUPERUSER', 'ADMINISTRATION_BLACKLIST_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/accounts"
          component={AccountsList}
          permissions={['SYS_SUPERUSER']}
        />
        <PrivateRoute
          exact
          path="/pos"
          component={POS}
          permissions={['RETAILER']}
        />
        <PrivateRoute
          exact
          path="/orders"
          component={OrdersList}
          permissions={[
            'ORDERS_VIEW',
            'ORDERS_REVIEW',
            'ORDERS_PRINT',
            'SYS_SUPERUSER',
          ]}
        />
        <PrivateRoute
          exact
          path="/orders/:id"
          component={EditOrder}
          permissions={[
            'ORDERS_VIEW',
            'ORDERS_REVIEW',
            'ORDERS_PRINT',
            'SYS_SUPERUSER',
          ]}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/institutions"
          component={InstitutionsList}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/institutions/:id"
          component={EditInstitution}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/tvm"
          component={TVMDashboard}
          permissions={['SYS_SUPERUSER', 'TVM_DASHBOARD_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/dashboard-validators"
          component={ValidatorsDashboard}
          permissions={['SYS_SUPERUSER', 'VIEW_VALIDATOR_MAINTENANCE']}
        />
        <PrivateRoute
          exact
          path="/tvm/burglary/:id"
          component={EditBurglary}
          permissions={['SYS_SUPERUSER', 'TVM_DASHBOARD_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/countries"
          component={CountriesList}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/countries/:id"
          component={EditCountry}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/reports/sales-report-mobile"
          component={SaleReportMobile}
          permissions={['SYS_SUPERUSER', 'REPORTS_SALES_MOBILE_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/reports/sales-report-portal"
          component={SaleReportPortal}
          permissions={['SYS_SUPERUSER', 'REPORTS_SALES_PORTAL_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/reports/sales-report-orders"
          component={SaleReportOrders}
          permissions={['SYS_SUPERUSER', 'REPORTS_SALES_ORDERS_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/reports/mobile-summary"
          component={MobileSummary}
          permissions={['SYS_SUPERUSER', 'REPORTS_MOBILE_SUMMARY_PERMISSION']}
        />
        <PrivateRoute
          exact
          path="/admin/taxonomies/system-parameters"
          component={SystemParametersList}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />{' '}
        <PrivateRoute
          exact
          path="/admin/taxonomies/system-parameters/:id"
          component={EditSystemParameter}
          permissions={['SYS_SUPERUSER', 'TAXONOMIES_PERMISSION']}
        />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  </AuthContextWrapper>
);

export default App;
