import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Badge, Space, Tabs, Row, Col, Collapse, Typography } from 'antd';

import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import useForm, { FormContext } from '../../../hooks/useForm';
import useDictionaries from '../../../hooks/useDictionaries';

import FormWrapper from '../../FormWrapper';

import SaveButton from '../../buttons/SaveButton';
import CancelButton from '../../buttons/CancelButton';

import RolesGrid from './RolesGrid';
import RestrictionsGrid from '../restrictions/RestrictionsGrid';

import { getTVARates } from '../../../services/taxonomies/tva-rate';
import ProductPricesGrid from './ProductPricesGrid';

import { findAll as findAllTokenSubtype } from '../../../services/taxonomies/token-subtypes';
import { findAll as findAllArcticleTypes } from '../../../services/stocks/article-types';
import { getUserTypes } from '../../../services/taxonomies/user-types';
import AdditionalInfo from './AdditionalInfo';
import { NominalTypes, ProductCategories } from '../OffersConstants';
import { PosTypes } from '../../poses/constants';

const VALIDITY_START_TYPES = [
  { id: 'SALE' },
  { id: 'VALIDATION' },
  { id: 'FUTURE' },
];
const DURATION_TYPES = [
  { id: 'DAY' },
  { id: 'WEEK' },
  { id: 'MONTH' },
  { id: 'YEAR' },
  { id: 'HOUR' },
  { id: 'MINUTE' },
  { id: 'CALENDAR_MONTH' },
  { id: 'CALENDAR_HALF_MONTH' },
  { id: 'CALENDAR_YEAR' },
  { id: 'CALENDAR_DAY' },
  { id: 'CALENDAR_WEEK' },
  { id: 'SCHOOL_YEAR' },
  { id: 'SCHOOL_YEAR_DYNAMIC' },
  { id: 'PENSIONER_YEAR' },
  { id: 'ACADEMIC_YEAR' },
  { id: 'ACADEMIC_YEAR_DYNAMIC' },
];
const TRANSPORT_TYPES = ['PASS', 'PURSE', 'TICKET', 'INTEGRATION', 'STOCABIL'];
const PURCHASABLE_TYPES = ['PASS', 'PURSE', 'TICKET'];

const dictionaries = {
  tvaRates: getTVARates,
  tokenSubtypes: findAllTokenSubtype,
  articleTypes: findAllArcticleTypes,
  userTypes: getUserTypes,
};

const EditProductForm = ({ product, onSubmit, onCancel }) => {
  const { t } = useTranslation();
  const [activeKeys, setActiveKey] = useState(['name']);
  const maxNameChar = 19;

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup
          .string()
          .required(t('errors.notEmpty'))
          .max(19, t('errors.maxLength', { max: maxNameChar })),
        nameEn: yup
          .string()
          .required(t('errors.notEmpty'))
          .max(19, t('errors.maxLength', { max: maxNameChar })),
        nameDe: yup
          .string()
          .required(t('errors.notEmpty'))
          .max(19, t('errors.maxLength', { max: maxNameChar })),
        vatRateId: yup.number().required(t('errors.mustSelected')),
        durationType: yup.string().required(t('errors.mustSelected')),
        duration: yup.number().required(t('errors.mustSelected')),
        nominalType: yup.string().required(t('errors.mustSelected')),
        category: yup.string().required(t('errors.mustSelected')),
        dateStart: yup.mixed().required(t('errors.notEmpty')),
        validityStartType: yup.string().required(t('errors.mustSelected')),
        code: yup.string().required(t('errors.notEmpty')),
        prices: yup.array().when('category', (category) => {
          return yup
            .array()
            .of(
              yup.object().shape({
                price: yup
                  .number()
                  .required(t('errors.notEmpty'))
                  .when('reportPrice', (reportPrice) => {
                    return yup
                      .number()
                      .required(t('errors.notEmpty'))
                      .max(
                        reportPrice,
                        `${t('errors.shouldBeGreaterThan')} ${reportPrice}`,
                      );
                  }),
                reportPrice: yup.number().required(t('errors.notEmpty')).min(0),
                dateStart: yup.mixed().required(t('errors.notEmpty')),
              }),
            )
            .min(
              TRANSPORT_TYPES.includes(category) ? 1 : 0,
              t('errors.notEmpty'),
            );
        }),
      }),
    [t],
  );

  const form = useForm({
    initialValue: product,
    schema: validationSchema,
    onSubmit,
    onCancel,
  });

  const [{ tvaRates, tokenSubtypes, articleTypes, userTypes }] =
    useDictionaries(dictionaries);

  const {
    handleSubmit,
    input,
    select,
    value,
    setValue,
    custom,
    setFormValue,
    errorMessages,
  } = form;

  useEffect(() => {
    setFormValue(product);
  }, [setFormValue, product]);

  const {
    isTransportTitle,
    isOperatorTitle,
    isStocabilTitle,
    isPurchasableTitle,
    isPassTitle,
  } = useMemo(
    () => ({
      isTransportTitle: TRANSPORT_TYPES.some((el) => el === value.category),
      isOperatorTitle: value.category === 'OPERATOR',
      isStocabilTitle: value.category === 'STOCABIL',
      isPurchasableTitle: PURCHASABLE_TYPES.some((el) => el === value.category),
      isPassTitle: value.category === 'PASS',
    }),
    [value.category],
  );

  useEffect(() => {
    switch (value.category) {
      case 'OPERATOR':
        setValue({
          restrictions: [],
          prices: [],
          articleTypeId: undefined,
          userTypeId: undefined,
        });
        break;
      case 'STOCABIL':
        setValue({ roles: [], userTypeId: undefined });
        break;
      case null:
      case undefined:
        break;
      case 'TICKET':
      case 'PURSE':
      case 'PASS':
        setValue({ roles: [], articleTypeId: undefined });
        break;
      default:
        setValue({
          roles: [],
          articleTypeId: undefined,
          userTypeId: undefined,
        });
    }
  }, [value.category, setValue]);

  useEffect(() => {
    if (
      errorMessages &&
      Object.keys(errorMessages).some((e) =>
        ['name', 'nameEn', 'nameDe'].includes(e),
      )
    ) {
      setActiveKey(['name']);
    }
  }, [errorMessages]);

  return (
    <FormContext.Provider value={form}>
      <form onSubmit={handleSubmit}>
        <FormWrapper>
          <FormWrapper.Single>
            {product.id && (
              <FormWrapper.Input
                label={t('entity.offers.product.id')}
                props={{ ...input('id'), disabled: true }}
              />
            )}

            <Typography.Paragraph style={{ marginBottom: 0 }}>
              {t('entity.offers.product.name._')}
            </Typography.Paragraph>
            <Collapse
              activeKey={activeKeys}
              onChange={() => setActiveKey(activeKeys ? null : 'name')}
            >
              <CollapsePanel key="name" header={value.name}>
                <FormWrapper.Input
                  label={t('entity.offers.product.name.ro')}
                  props={{ ...input('name'), disabled: value.deleted }}
                />
                <FormWrapper.Input
                  label={t('entity.offers.product.name.en')}
                  props={{ ...input('nameEn'), disabled: value.deleted }}
                />
                <FormWrapper.Input
                  label={t('entity.offers.product.name.de')}
                  props={{ ...input('nameDe'), disabled: value.deleted }}
                />
              </CollapsePanel>
            </Collapse>
            <FormWrapper.Input
              label={t('entity.offers.product.code')}
              props={{ ...input('code'), disabled: value.deleted }}
            />
            <FormWrapper.Select
              label={t('entity.offers.product.vatRate')}
              props={{
                options: tvaRates.content,
                loading: tvaRates.loading,
                labelProp: (obj) =>
                  obj.description
                    ? `${obj.tvaRate} % - ${obj.description}`
                    : `${obj.tvaRate} %`,
                ...select('vatRateId'),
                disabled: value.deleted,
              }}
            />
            <FormWrapper.Input
              label={t('entity.offers.product.unit')}
              props={{ ...input('unit'), disabled: value.deleted }}
            />
            <Row gutter={8}>
              <Col span={12}>
                <FormWrapper.Select
                  label={t('entity.offers.product.durationType._')}
                  props={{
                    options: DURATION_TYPES,
                    ...select('durationType'),
                    disabled: value.deleted,
                    labelProp: (val) =>
                      t(`entity.offers.product.durationType.${val.id}`),
                  }}
                />
              </Col>
              <Col span={12}>
                <FormWrapper.Number
                  label={t('entity.offers.product.duration')}
                  props={{
                    ...input('duration'),
                    min: 1,
                    disabled: value.deleted,
                  }}
                />
              </Col>
            </Row>
            <FormWrapper.Select
              label={t('entity.offers.product.category._')}
              props={{
                options: ProductCategories.content,
                loading: ProductCategories.loading,
                ...select('category'),
                disabled: value.deleted,
                labelProp: (val) =>
                  t(`entity.offers.product.category.${val.id}`),
              }}
            />
            <FormWrapper.Select
              label={t('entity.taxonomy.tokenSubtype._plural')}
              props={{
                options: tokenSubtypes.content,
                loading: tokenSubtypes.loading,
                mode: 'multiple',
                allowClear: true,
                ...select('tokenSubtypes'),
                disabled: value.deleted,
              }}
            />
            <FormWrapper.Select
              label={t('entity.taxonomy.poses.type._')}
              props={{
                options: PosTypes.content,
                loading: PosTypes.loading,
                mode: 'multiple',
                allowClear: true,
                ...select('posTypes'),
                disabled: value.deleted,
                labelProp: (val) => t(`entity.taxonomy.poses.type.${val.id}`),
              }}
            />
            <FormWrapper.Select
              label={t('entity.offers.product.nominalType._')}
              props={{
                options: NominalTypes.content,
                ...select('nominalType'),
                disabled: value.deleted,
                labelProp: (val) =>
                  t(`entity.offers.product.nominalType.${val.id}`),
              }}
            />
            <FormWrapper.Select
              label={t('entity.offers.product.validityStartType._')}
              props={{
                options: VALIDITY_START_TYPES,
                ...select('validityStartType'),
                disabled: value.deleted,
                labelProp: (val) =>
                  t(`entity.offers.product.validityStartType.${val.id}`),
              }}
            />
            {isStocabilTitle && (
              <FormWrapper.Select
                label={t('entity.taxonomy.articleType._singular')}
                props={{
                  options: articleTypes.content,
                  loading: articleTypes.loading,
                  ...select('articleTypeId'),
                  disabled: value.deleted,
                }}
              />
            )}
            {isPurchasableTitle && (
              <FormWrapper.Select
                label={t('entity.taxonomy.userType._singular')}
                props={{
                  options: [{ id: null, name: '' }, ...userTypes.content],
                  loading: userTypes.loading,
                  ...select('userTypeId'),
                  disabled: value.deleted,
                }}
              />
            )}
            <FormWrapper.RangePicker
              label={`${t('entity.offers.product.dateStart')} / ${t(
                'entity.offers.product.dateEnd',
              )}`}
              props={{
                start: custom('dateStart'),
                end: custom('dateEnd'),
                allowEmpty: [false, true],
                disabled: value.deleted,
              }}
            />
            <FormWrapper.Number
              label={t('entity.offers.product.allowedCredit')}
              props={{
                ...input('allowedCredit'),
                min: '0',
                step: '1',
                disabled: value.deleted,
              }}
            />
          </FormWrapper.Single>
          <FormWrapper.Double>
            <Tabs>
              {isOperatorTitle && (
                <Tabs.TabPane
                  tab={
                    <>
                      {t('entity.offers.product.roles')}{' '}
                      {errorMessages?.roles && <Badge status="error" />}
                    </>
                  }
                  key="roles"
                >
                  <RolesGrid />
                </Tabs.TabPane>
              )}

              {isTransportTitle && (
                <Tabs.TabPane
                  tab={t('entity.offers.product.restrictions')}
                  key="restrictions"
                >
                  <RestrictionsGrid />
                </Tabs.TabPane>
              )}

              {isTransportTitle && (
                <Tabs.TabPane
                  tab={
                    <>
                      {t('entity.offers.product.prices._')}{' '}
                      {Object.keys(errorMessages || {}).some((key) =>
                        key.includes('prices'),
                      ) && <Badge status="error" />}
                    </>
                  }
                  key="prices"
                >
                  <ProductPricesGrid productCode={product?.code} />
                </Tabs.TabPane>
              )}

              <Tabs.TabPane
                tab={<>{t('entity.offers.product.additionalInfo._')} </>}
                key="additional"
              >
                <AdditionalInfo isPassTitle={isPassTitle} />
              </Tabs.TabPane>
            </Tabs>
          </FormWrapper.Double>
        </FormWrapper>

        <Space>
          {!value.deleted && <SaveButton />}
          <CancelButton onCancel={onCancel} />
        </Space>
      </form>
    </FormContext.Provider>
  );
};

export default EditProductForm;
