import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Layout, Menu, message, Spin } from 'antd';

import { getPosById } from '../services/taxonomies/poses';

import AuthContext from '../components/auth';
import { AUTH_SERVICE_URI, AUTH_BASE_URI } from '../services/auth';
import { checkPosByIdentifier, identify } from '../services/pos/pos';

const Clean = ({ children }) => {
  const [title, setTitle] = useState('');
  const { user } = useContext(AuthContext);
  const [checked, setChecked] = useState(false);
  const { t } = useTranslation();

  const handleLogout = () => {
    window.location.href = `${window._env_.API_BACKEND_URL}${AUTH_SERVICE_URI}${AUTH_BASE_URI}/logout`;
  };

  useEffect(() => {
    const checkIdentifier = async () => {
      try {
        const result = await identify();
        const res = await checkPosByIdentifier(result?.workstationToken);
        if (res != null) {
          const pos = await getPosById(res?.id);
          setTitle(pos.name);
          setChecked(true);
        }
      } catch (err) {
        message.error({
          content: t('errors.shiftOpenAnotherStationOrMissingIdentifier'),
          key: 'pos',
          duration: 5,
          className: 'card-message',
        });
        setTimeout(() => handleLogout(), 5000);
        console.error(err);
      }
    };
    checkIdentifier();
  }, [t]);

  return (
    <>
      {!checked ? (
        <Spin
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      ) : (
        <>
          <Layout>
            <Layout.Header
              style={{
                position: 'fixed',
                zIndex: 1000,
                width: '100%',
                display: 'flex',
              }}
            >
              <div style={{ color: 'white', flexGrow: 1 }}>UrbanGO! - POS</div>
              <div style={{ color: 'white', flexGrow: 8 }}>
                Punct de vânzare: {title}
              </div>

              <Menu
                theme="dark"
                mode="horizontal"
                style={{ minWidth: '200px' }}
              >
                <Menu.SubMenu
                  style={{ float: 'right' }}
                  key="account"
                  title={user.email}
                  popupOffset={[0, -10]}
                >
                  <Menu.Item key="logout" onClick={handleLogout}>
                    {t('navigation.logout')}
                  </Menu.Item>
                </Menu.SubMenu>
              </Menu>
            </Layout.Header>

            <Layout>
              <Layout
                style={{
                  height: '100vh',
                  minHeight: '400px',
                  borderRight: 0,
                  paddingTop: '64px',
                }}
              >
                <div
                  style={{
                    padding: 24,
                    margin: 0,
                    height: '100%',
                    display: 'flex',
                    alignItems: 'stretch',
                  }}
                >
                  <Layout.Content
                    style={{
                      background: 'white',
                      minHeight: '100%',
                      padding: 24,
                    }}
                  >
                    {children}
                  </Layout.Content>
                </div>
              </Layout>
            </Layout>
          </Layout>
          {window._env_.VERSION && (
            <div className="app-version">
              {new Date(
                (parseInt(window._env_.VERSION, 10) || 0) * 1000,
              ).toISOString()}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Clean;
