import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Modal, notification, Space } from 'antd';
import {
  CloseOutlined,
  ReconciliationOutlined,
  RollbackOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { CUSTOMER_TYPE, ORDER_STATUS } from '../../pages/orders/constants';
import { useRouteParams } from '../../hooks/useRouteParams';
import AuthContext, { hasPermission } from '../auth';

import { update } from '../../services/orders';
import PopupConfirmButton from '../buttons/PopupConfirmButton';

const IssuingOrderButtons = ({ form, formRef, hasContract }) => {
  const { t } = useTranslation();
  const { isNew } = useRouteParams();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [cancelingForm] = Form.useForm();
  const {
    user: { permissions },
  } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [isInvoiceEnabledString] = useState(window._env_.ENABLE_ERP);
  const isInvoiceEnabled = isInvoiceEnabledString === 'true';
  const { status, commandType, fiscalBillId, customerType, products } =
    form.value;

  const totalPrice = useMemo(() => {
    return products
      .map((product) => {
        const { price } = product;
        const qty = product.persons.length;
        return (price * qty) / 100;
      })
      .reduce((acc, val) => acc + val, 0.0);
  }, [products]);

  const addCommentaryOnRemove = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const submitWithAction = useCallback(
    (action, reason) => {
      if (
        ORDER_STATUS.CONFIRMED === action ||
        ORDER_STATUS.REJECTED === action ||
        action === 'proform'
      ) {
        if (ORDER_STATUS.REJECTED === action) {
          const order = form.value;
          if (reason !== null && reason !== undefined && reason.length > 0) {
            order.cancelReason = reason;
            setIsModalVisible(false);
          } else {
            notification.error({
              message: t('errors.selectReason'),
            });
            return;
          }
        }
        setLoading(true);
        update({ ...form.value, action })
          .then((order) => {
            form.setFormValue({
              ...order,
              disabled: order.status !== ORDER_STATUS.SELECT_PRODUCTS,
            });
            notification.success({
              message: t('actions.saveSuccessful'),
            });
            setLoading(false);
          })
          .catch((msg) => {
            console.error(msg);
            const { inner } = msg || {};
            const { _: messageCode } = inner || {};

            notification.error({
              message: messageCode
                ? t(messageCode, inner)
                : t('actions.saveFailed'),
            });
            setLoading(false);
            return Promise.reject(msg);
          });
      } else {
        form.setValue('action', action);
        formRef.current.dispatchEvent(new Event('submit'));
      }
    },
    [form, formRef, t],
  );

  return (
    <>
      <Space>
        {status === ORDER_STATUS.SELECT_PRODUCTS &&
          !customerType &&
          hasPermission(permissions, ['SYS_SUPERUSER', 'ORDERS_VIEW']) && (
            <Button
              className="ant-btn-success"
              type="primary"
              icon={<SaveOutlined />}
              htmlType="submit"
              onClick={() =>
                submitWithAction(ORDER_STATUS.DOC_VALIDATION, null)
              }
            >
              {customerType !== CUSTOMER_TYPE.LEGAL
                ? t('actions.orders.docValidation')
                : t('actions.orders.nextStep')}
            </Button>
          )}
        {status === ORDER_STATUS.OP_VALIDATION &&
          hasPermission(permissions, ['SYS_SUPERUSER', 'ORDERS_VIEW']) && (
            <Button
              className="ant-btn-success"
              type="primary"
              icon={<SaveOutlined />}
              htmlType="submit"
              onClick={() => {
                submitWithAction(ORDER_STATUS.PAID, null);
              }}
            >
              {t('actions.orders.OPValidation')}
            </Button>
          )}
        {status === ORDER_STATUS.DOC_VALIDATION &&
          hasPermission(permissions, ['SYS_SUPERUSER', 'ORDERS_REVIEW']) && (
            <>
              {commandType === 'INTERNAL' && (
                <Button
                  className="ant-btn-success"
                  type="primary"
                  htmlType="submit"
                  icon={<SaveOutlined />}
                  onClick={() => submitWithAction(ORDER_STATUS.PAYMENT, null)}
                >
                  {t('actions.orders.nextStep')}
                </Button>
              )}
              {commandType === 'EXTERNAL' && (
                <Button
                  htmlType="submit"
                  type="primary"
                  disabled={loading}
                  icon={<ReconciliationOutlined />}
                  onClick={() => submitWithAction('proform', null)}
                >
                  {isInvoiceEnabled && customerType !== CUSTOMER_TYPE.INDIVIDUAL
                    ? t('actions.orders.generateProform')
                    : t('actions.orders.nextStep')}
                </Button>
              )}
            </>
          )}
        {[ORDER_STATUS.PAYMENT_DUE, ORDER_STATUS.PAYMENT].includes(status) &&
          hasPermission(permissions, ['SYS_SUPERUSER', 'ORDERS_REVIEW']) && (
            <Space>
              {!fiscalBillId && customerType !== CUSTOMER_TYPE.INDIVIDUAL ? (
                <>
                  {isInvoiceEnabled ? (
                    <PopupConfirmButton
                      className="ant-btn-success"
                      buttonName={t('actions.orders.customizeCard')}
                      title={t('actions.orders.customizeCardQuestion')}
                      item={ORDER_STATUS.CUSTOMIZE_CARD}
                      action={submitWithAction}
                      disabled={
                        customerType !== null &&
                        !hasContract &&
                        commandType === 'EXTERNAL' &&
                        ORDER_STATUS.PAYMENT === status &&
                        totalPrice > 0
                      }
                      reload={() => null}
                      size="medium"
                      formRef={formRef}
                    />
                  ) : (
                    <Button
                      className="ant-btn-success"
                      type="primary"
                      htmlType="submit"
                      icon={<SaveOutlined />}
                      onClick={() =>
                        submitWithAction(ORDER_STATUS.CUSTOMIZE_CARD, null)
                      }
                      disabled={
                        customerType !== null &&
                        !hasContract &&
                        commandType === 'EXTERNAL' &&
                        ORDER_STATUS.PAYMENT === status &&
                        totalPrice > 0
                      }
                    >
                      {t('actions.orders.customizeCard')}
                    </Button>
                  )}

                  {isInvoiceEnabled && (
                    <PopupConfirmButton
                      className="ant-btn-primary"
                      buttonName={t('actions.orders.generateInvoiceNoPayment')}
                      title={t(
                        'actions.orders.generateInvoiceNoPaymentQuestion',
                      )}
                      item="fiscal-bill"
                      action={submitWithAction}
                      disabled={
                        customerType !== null &&
                        (customerType === CUSTOMER_TYPE.INDIVIDUAL ||
                          (customerType === CUSTOMER_TYPE.LEGAL &&
                            !hasContract)) &&
                        commandType === 'EXTERNAL' &&
                        ORDER_STATUS.PAYMENT === status &&
                        totalPrice > 0
                      }
                      reload={() => null}
                      size="medium"
                      formRef={formRef}
                    />
                  )}
                </>
              ) : (
                <Button
                  className="ant-btn-success"
                  type="primary"
                  htmlType="submit"
                  disabled={
                    customerType !== null &&
                    (customerType === CUSTOMER_TYPE.INDIVIDUAL ||
                      (customerType === CUSTOMER_TYPE.LEGAL && !hasContract)) &&
                    commandType === 'EXTERNAL' &&
                    ORDER_STATUS.PAYMENT === status &&
                    totalPrice > 0
                  }
                  icon={<SaveOutlined />}
                  onClick={() =>
                    submitWithAction(ORDER_STATUS.CUSTOMIZE_CARD, null)
                  }
                >
                  {t('actions.orders.customizeCard')}
                </Button>
              )}
            </Space>
          )}
        {status === ORDER_STATUS.PAID &&
          hasPermission(permissions, ['SYS_SUPERUSER', 'ORDERS_REVIEW']) && (
            <>
              {customerType !== CUSTOMER_TYPE.INDIVIDUAL &&
              !fiscalBillId &&
              isInvoiceEnabled ? (
                <PopupConfirmButton
                  className="ant-btn-success"
                  buttonName={t('actions.orders.customizeCard')}
                  title={t('actions.orders.customizeCardQuestion')}
                  item={ORDER_STATUS.CUSTOMIZE_CARD}
                  action={submitWithAction}
                  reload={() => null}
                  size="medium"
                  formRef={formRef}
                />
              ) : (
                <Button
                  className="ant-btn-success"
                  type="primary"
                  htmlType="submit"
                  icon={<SaveOutlined />}
                  onClick={() =>
                    submitWithAction(ORDER_STATUS.CUSTOMIZE_CARD, null)
                  }
                >
                  {t('actions.orders.customizeCard')}
                </Button>
              )}

              {isInvoiceEnabled &&
                !fiscalBillId &&
                commandType === 'EXTERNAL' &&
                customerType !== CUSTOMER_TYPE.INDIVIDUAL && (
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<ReconciliationOutlined />}
                    onClick={() => submitWithAction('fiscal-bill', null)}
                  >
                    {t('actions.orders.generateInvoice')}
                  </Button>
                )}
            </>
          )}
        {status === ORDER_STATUS.CUSTOMIZE_CARD &&
          hasPermission(permissions, ['SYS_SUPERUSER', 'ORDERS_REVIEW']) && (
            <Button
              className="ant-btn-success"
              type="primary"
              htmlType="submit"
              icon={<SaveOutlined />}
              onClick={() => submitWithAction(ORDER_STATUS.PRINTING, null)}
            >
              {t('actions.orders.toPrinting')}
            </Button>
          )}
        {status === ORDER_STATUS.PRINTING &&
          hasPermission(permissions, ['SYS_SUPERUSER', 'ORDERS_PRINT']) && (
            <Button
              className="ant-btn-success"
              type="primary"
              htmlType="submit"
              disabled={loading}
              icon={<SaveOutlined />}
              onClick={() => submitWithAction(ORDER_STATUS.CONFIRMED, null)}
            >
              {t('actions.orders.finish')}
            </Button>
          )}
        {![
          ORDER_STATUS.REJECTED,
          ORDER_STATUS.CONFIRMED,
          ORDER_STATUS.PICKED_UP,
        ].includes(status) &&
          (status !== ORDER_STATUS.SELECT_PRODUCTS || !customerType) &&
          !isNew && (
            <Button
              type="danger"
              htmlType="button"
              onClick={() => addCommentaryOnRemove()}
              icon={<CloseOutlined />}
            >
              {t('actions.reject')}
            </Button>
          )}
      </Space>

      <Modal
        title={t('entity.orders.cancelReason')}
        visible={isModalVisible}
        closable={false}
        footer={
          <Space>
            <Button
              className="ant-btn-danger"
              type="primary"
              htmlType="submit"
              icon={<CloseOutlined />}
              onClick={() =>
                submitWithAction(
                  ORDER_STATUS.REJECTED,
                  cancelingForm.getFieldValue('motiv'),
                )
              }
            >
              {t('actions.reject')}
            </Button>
            <Button
              className="ant-btn-primary"
              type="primary"
              htmlType="button"
              icon={<RollbackOutlined />}
              onClick={() => setIsModalVisible(false)}
            >
              {t('actions.cancel')}
            </Button>
          </Space>
        }
      >
        <Form form={cancelingForm} layout="vertical">
          <Form.Item
            name={['motiv']}
            label={t('entity.orders.reasonCancelOrder')}
            rules={[
              {
                required: true,
                message: t('errors.notEmpty'),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default IssuingOrderButtons;
