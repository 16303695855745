/* eslint-disable no-await-in-loop */
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { FormContext } from '../../hooks/useForm';

import POSButton from '../pos2/POSButton';

import { ejectCard } from '../../services/stocks/cards';
import {
  downloadImage,
  getNextEnrolled,
  print,
  showMessage,
} from '../../helpers/printing';
import { assignPersonWithCard } from '../../services/orders';

const Printing = () => {
  const { t } = useTranslation();
  const form = useContext(FormContext);
  const [templates, setTemplates] = useState({});

  const { value, setValue } = form;

  const { products = [] } = value;

  const getImage = useCallback(
    async (id) => {
      let image = templates[id];
      if (!image) {
        image = await downloadImage(id);
        setTemplates({ ...templates, id: image });
      }
      return image;
    },
    [templates],
  );

  const getPrintText = useCallback((person) => {
    const { fullname, employeeNumber, identityCard } = person;

    const nr = employeeNumber
      ? `${employeeNumber}`
      : `${(identityCard || '').substring(0, 7)}`;
    return ` \n${nr}\n${fullname}`;
  }, []);

  const beginPrinting = useCallback(async () => {
    const allPersons = products
      .filter((p) =>
        products.length > 1 ? p.productCode !== 'MIFARE_1K' : true,
      )
      .map(({ persons, templateFileId, roleId }) =>
        persons.map((p) => ({ ...p, templateFileId, roleId })),
      )
      .flat();
    const allNotPrinted = allPersons.filter((p) => !p.printed);

    const done = {};
    try {
      // eslint-disable-next-line no-restricted-syntax
      for (const pers of allNotPrinted) {
        const { templateFileId, fullname, id } = pers;
        showMessage('loading', 'Se citeste cardul', 0);
        const { physicalId, serial } = await getNextEnrolled(allPersons, t);
        showMessage(
          'loading',
          `Se tipareste cardul ${physicalId} pentru ${fullname}`,
        );
        const layout = await getImage(templateFileId);
        await print([layout], getPrintText(pers));
        await assignPersonWithCard(pers.id, value.id, {
          cardPhysicalId: physicalId,
          cardLogicalId: serial,
        })
          .then(() => {
            done[id] = { physicalId, serial };
          })
          .catch((err) => {
            throw err.inner._;
          });
      }
      showMessage(
        'success',
        `Printare finisata. Număr de carduri procesate: ${allPersons.length}`,
      );
    } catch (err) {
      try {
        await ejectCard('REJECT');
      } catch (err2) {
        console.error(err2);
      }
      showMessage('error', err);
    }
    setValue(
      'products',
      products.map(({ persons, ...rest }) => ({
        ...rest,
        persons: persons.map(({ id, ...props }) => {
          const newProps = done[id]
            ? {
                printed: true,
                cardPhysicalId: done[id].physicalId,
                cardLogicalId: done[id].serial,
              }
            : {};

          return {
            id,
            ...props,
            ...newProps,
          };
        }),
      })),
    );
  }, [products, setValue, getImage, getPrintText, value.id, t]);

  return (
    <Row
      gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}
      style={{ padding: '2rem', textAlign: 'center' }}
    >
      <Col span={6} lg={{ span: 4, offset: 10 }}>
        <POSButton
          size="small"
          title={t('actions.orders.print')}
          color="green"
          onClick={beginPrinting}
        />
      </Col>
    </Row>
  );
};

export default Printing;
