import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Modal, notification, Space } from 'antd';
import {
  CloseOutlined,
  ReconciliationOutlined,
  RollbackOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { CUSTOMER_TYPE, ORDER_STATUS } from '../../pages/orders/constants';
import { useRouteParams } from '../../hooks/useRouteParams';
import { update } from '../../services/orders';
import AuthContext, { hasPermission } from '../auth';
import PopupConfirmButton from '../buttons/PopupConfirmButton';

const RechargingOrderButtons = ({ form, formRef, hasContract }) => {
  const { t } = useTranslation();
  const { isNew } = useRouteParams();
  const [loading, setLoading] = useState(false);
  const {
    user: { permissions },
  } = useContext(AuthContext);

  const { status, commandType, customerType, products } = form.value;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [cancelingForm] = Form.useForm();
  const [isInvoiceEnabledString] = useState(window._env_.ENABLE_ERP);
  const isInvoiceEnabled = isInvoiceEnabledString === 'true';

  const totalPrice = useMemo(() => {
    return products
      .map((product) => {
        const { price } = product;
        const qty = product.persons.length;
        return (price * qty) / 100;
      })
      .reduce((acc, val) => acc + val, 0.0);
  }, [products]);

  const submitWithAction = useCallback(
    (action, reason) => {
      if (ORDER_STATUS.REJECTED === action || action === 'proform') {
        const orderForm = form.value;
        if (ORDER_STATUS.REJECTED === action) {
          if (reason !== null && reason !== undefined && reason.length > 0) {
            orderForm.cancelReason = reason;
            setIsModalVisible(false);
          } else {
            notification.error({
              message: t('errors.selectReason'),
            });
            return;
          }
        }
        setLoading(true);
        form.setValue('action', action);
        update({ ...form.value, action })
          .then((order) => {
            form.setFormValue({
              ...order,
              disabled: order.status !== ORDER_STATUS.SELECT_PRODUCTS,
            });
            notification.success({
              message: t('actions.saveSuccessful'),
            });
            setLoading(false);
          })
          .catch((msg) => {
            console.error(msg);
            const { inner } = msg || {};
            const { _: messageCode } = inner || {};

            notification.error({
              message: messageCode
                ? t(messageCode, inner)
                : t('actions.saveFailed'),
            });
            setLoading(false);
            return Promise.reject(msg);
          });
      } else {
        form.setValue('action', action);
        formRef.current.dispatchEvent(new Event('submit'));
      }
    },
    [form, formRef, t],
  );

  const addCommentaryOnRemove = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  return (
    <>
      <Space>
        {status === ORDER_STATUS.SELECT_PRODUCTS &&
          !customerType &&
          hasPermission(permissions, ['SYS_SUPERUSER', 'ORDERS_VIEW']) && (
            <Button
              className="ant-btn-success"
              type="primary"
              icon={<SaveOutlined />}
              htmlType="submit"
              onClick={() =>
                submitWithAction(ORDER_STATUS.DOC_VALIDATION, null)
              }
            >
              {customerType !== CUSTOMER_TYPE.LEGAL
                ? t('actions.orders.docValidation')
                : t('actions.orders.nextStep')}
            </Button>
          )}
        {status === ORDER_STATUS.DOC_VALIDATION &&
          hasPermission(permissions, ['SYS_SUPERUSER', 'ORDERS_REVIEW']) && (
            <>
              {commandType === 'INTERNAL' && (
                <Button
                  className="ant-btn-success"
                  type="primary"
                  htmlType="submit"
                  icon={<SaveOutlined />}
                  onClick={() => submitWithAction(ORDER_STATUS.PAYMENT, null)}
                >
                  {t('actions.orders.nextStep')}
                </Button>
              )}
              {commandType === 'EXTERNAL' && (
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={loading}
                  icon={<ReconciliationOutlined />}
                  onClick={() => submitWithAction('proform', null)}
                >
                  {isInvoiceEnabled && customerType !== CUSTOMER_TYPE.INDIVIDUAL
                    ? t('actions.orders.generateProform')
                    : t('actions.orders.nextStep')}
                </Button>
              )}
            </>
          )}
        {status === ORDER_STATUS.OP_VALIDATION &&
          hasPermission(permissions, ['SYS_SUPERUSER', 'ORDERS_VIEW']) && (
            <Button
              className="ant-btn-success"
              type="primary"
              icon={<SaveOutlined />}
              htmlType="submit"
              onClick={() => {
                submitWithAction(ORDER_STATUS.PAID, null);
              }}
            >
              Validare OP
            </Button>
          )}
        {ORDER_STATUS.PAYMENT === status &&
          hasPermission(permissions, ['SYS_SUPERUSER', 'ORDERS_REVIEW']) && (
            <Space>
              {isInvoiceEnabled ? (
                <PopupConfirmButton
                  className="ant-btn-success"
                  buttonName={t('actions.orders.finish')}
                  title={t('actions.orders.finishQuestion')}
                  item={ORDER_STATUS.CONFIRMED}
                  action={submitWithAction}
                  disabled={
                    customerType !== null &&
                    (customerType === CUSTOMER_TYPE.INDIVIDUAL ||
                      (customerType === CUSTOMER_TYPE.LEGAL && !hasContract)) &&
                    commandType === 'EXTERNAL' &&
                    ORDER_STATUS.PAYMENT === status &&
                    totalPrice > 0
                  }
                  reload={() => null}
                  size="medium"
                  formRef={formRef}
                />
              ) : (
                <Button
                  className="ant-btn-success"
                  type="primary"
                  htmlType="submit"
                  disabled={
                    customerType !== null &&
                    (customerType === CUSTOMER_TYPE.INDIVIDUAL ||
                      (customerType === CUSTOMER_TYPE.LEGAL && !hasContract)) &&
                    commandType === 'EXTERNAL' &&
                    ORDER_STATUS.PAYMENT === status &&
                    totalPrice > 0
                  }
                  icon={<SaveOutlined />}
                  onClick={() => submitWithAction(ORDER_STATUS.CONFIRMED, null)}
                >
                  {t('actions.orders.finish')}
                </Button>
              )}

              {isInvoiceEnabled && (
                <PopupConfirmButton
                  className="ant-btn-primary"
                  buttonName={t('actions.orders.generateInvoiceNoPayment')}
                  title={t('actions.orders.generateInvoiceNoPaymentQuestion')}
                  item="fiscal-bill"
                  action={submitWithAction}
                  disabled={
                    customerType !== null &&
                    (customerType === CUSTOMER_TYPE.INDIVIDUAL ||
                      (customerType === CUSTOMER_TYPE.LEGAL && !hasContract)) &&
                    commandType === 'EXTERNAL' &&
                    ORDER_STATUS.PAYMENT === status &&
                    totalPrice > 0
                  }
                  reload={() => null}
                  size="medium"
                  formRef={formRef}
                />
              )}
            </Space>
          )}
        {ORDER_STATUS.PAYMENT_DUE === status &&
          hasPermission(permissions, ['SYS_SUPERUSER', 'ORDERS_REVIEW']) && (
            <Button
              type="primary"
              htmlType="submit"
              icon={<ReconciliationOutlined />}
              onClick={() => submitWithAction('fiscal-bill', null)}
            >
              {t('actions.orders.generateInvoiceNoPayment')}
            </Button>
          )}
        {ORDER_STATUS.PAID === status &&
          hasPermission(permissions, ['SYS_SUPERUSER', 'ORDERS_REVIEW']) && (
            <>
              {commandType === 'INTERNAL' && (
                <Button
                  className="ant-btn-success"
                  type="primary"
                  htmlType="submit"
                  icon={<SaveOutlined />}
                  onClick={() => submitWithAction(ORDER_STATUS.CONFIRMED, null)}
                >
                  {t('actions.orders.finish')}
                </Button>
              )}
              {commandType === 'EXTERNAL' && isInvoiceEnabled && (
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<ReconciliationOutlined />}
                  onClick={() => submitWithAction('fiscal-bill', null)}
                >
                  {customerType !== CUSTOMER_TYPE.INDIVIDUAL
                    ? t('actions.orders.generateInvoice')
                    : t('actions.orders.finish')}
                </Button>
              )}
              {commandType === 'EXTERNAL' && !isInvoiceEnabled && (
                <Button
                  className="ant-btn-success"
                  type="primary"
                  htmlType="submit"
                  icon={<SaveOutlined />}
                  onClick={() => submitWithAction(ORDER_STATUS.CONFIRMED, null)}
                >
                  {t('actions.orders.finish')}
                </Button>
              )}
            </>
          )}
        {![
          ORDER_STATUS.REJECTED,
          ORDER_STATUS.CONFIRMED,
          ORDER_STATUS.PICKED_UP,
        ].includes(status) &&
          (status !== ORDER_STATUS.SELECT_PRODUCTS || !customerType) &&
          !isNew && (
            <Button
              type="danger"
              htmlType="button"
              onClick={() => addCommentaryOnRemove()}
              icon={<CloseOutlined />}
            >
              {t('actions.reject')}
            </Button>
          )}
      </Space>
      <Modal
        title={t('entity.orders.cancelReason')}
        visible={isModalVisible}
        closable={false}
        footer={
          <Space>
            <Button
              className="ant-btn-danger"
              type="primary"
              htmlType="submit"
              icon={<CloseOutlined />}
              onClick={() =>
                submitWithAction(
                  ORDER_STATUS.REJECTED,
                  cancelingForm.getFieldValue('motiv'),
                )
              }
            >
              {t('actions.reject')}
            </Button>
            <Button
              className="ant-btn-primary"
              type="primary"
              htmlType="button"
              icon={<RollbackOutlined />}
              onClick={() => setIsModalVisible(false)}
            >
              {t('actions.cancel')}
            </Button>
          </Space>
        }
      >
        <Form form={cancelingForm} layout="vertical">
          <Form.Item
            name={['motiv']}
            label={t('entity.orders.reasonCancelOrder')}
            rules={[
              {
                required: true,
                message: t('errors.notEmpty'),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default RechargingOrderButtons;
