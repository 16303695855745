import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, notification, Table, Tooltip } from 'antd';
import { RedoOutlined, StopOutlined } from '@ant-design/icons';
import useDatasource from '../../hooks/useDatasource';
import Column from '../../helpers/columns';

import PageTitle from '../PageTitle';
import ResizableHeaderCell from '../../helpers/ResizableHeaderCell';
import {
  cancelInvoiceGeneration,
  getTransactions,
  retryInvoiceGeneration,
} from '../../services/accounts/accounts';

const EvenTypes = {
  content: [{ id: 'ADD_NEW' }, { id: 'RECHARGE' }],
  loading: false,
  error: false,
};

const Sources = {
  content: [
    { id: 'MOBILE' },
    { id: 'PORTAL' },
    { id: 'TELELINK' },
    { id: 'TVM' },
  ],
  loading: false,
  error: false,
};

const RetryInvoiceGenerationGrid = () => {
  const { t } = useTranslation();

  const { pagination, content, handleChange, reload } = useDatasource((arg) =>
    getTransactions({
      ...arg,
      criterias: {
        ...arg.criterias,
        erpInvoiceStatus: 'FAILED',
      },
    }),
  );

  const handleRetryGeneration = useCallback(
    (record) => {
      retryInvoiceGeneration(record)
        .then((data) => {
          console.log('data', data);
          reload();
        })
        .catch((msg) => {
          console.error(msg);
          const { inner } = msg || {};
          const { _: messageCode } = inner || {};

          notification.error({
            message: messageCode ? t(messageCode) : t('actions.deleteFailed'),
          });
        });
    },
    [t, reload],
  );

  const handleCancelGeneration = useCallback(
    (record) => {
      cancelInvoiceGeneration(record)
        .then((data) => {
          console.log('data', data);
          reload();
        })
        .catch((msg) => {
          console.error(msg);
          const { inner } = msg || {};
          const { _: messageCode } = inner || {};

          notification.error({
            message: messageCode ? t(messageCode) : t('actions.deleteFailed'),
          });
        });
    },
    [t, reload],
  );

  useEffect(() => {
    if (content?.[0]) {
      localStorage.setItem('julianStartDate', content[0].julianStartDate);
    }
  }, [content]);

  const columns = useMemo(
    () => [
      Column.text(
        'userName',
        t('pages.reports.retryInvoiceGeneration.transactions.userName'),
        {
          filter: false,
          sort: false,
        },
      ),
      Column.text(
        'userIdentifier',
        t('pages.reports.retryInvoiceGeneration.transactions.userIdentifier'),
        {
          filter: false,
          sort: false,
        },
      ),
      Column.date('createdAt', t('entity.orders.createdAt'), {
        width: 250,
      }),
      Column.dictionary(
        'eventType',
        t('pages.reports.retryInvoiceGeneration.transactions.eventType._'),
        EvenTypes,
        {
          filter: false,
          width: 150,
          dictLabel: (entry) => {
            return t(
              `pages.reports.retryInvoiceGeneration.transactions.eventType.${entry.id}`,
            );
          },
        },
      ),
      Column.dictionary(
        'saleChanel',
        t('pages.reports.retryInvoiceGeneration.transactions.source._'),
        Sources,
        {
          filter: false,
          width: 150,
          dictLabel: (entry) => {
            return t(
              `pages.reports.retryInvoiceGeneration.transactions.source.${entry.id}`,
            );
          },
        },
      ),
      Column.text(
        'parentProdName',
        t('pages.reports.retryInvoiceGeneration.transactions.productName'),
        {
          filter: false,
          sort: false,
        },
      ),
      Column.text(
        'parentProdQty',
        t('pages.reports.retryInvoiceGeneration.transactions.qty'),
        {
          filter: false,
          sort: false,
          width: 100,
        },
      ),
      Column.text(
        'orderId',
        t('pages.reports.retryInvoiceGeneration.transactions.orderId'),
        {
          filter: false,
          sort: false,
          width: 120,
        },
      ),
      {
        title: `${t(
          'pages.reports.retryInvoiceGeneration.transactions.price',
        )} (RON)`,
        key: 'id',
        width: 100,
        sorter: false,
        render: (v) => {
          const { parentProdPrice } = v;
          return (parentProdPrice / 100).toFixed(2);
        },
      },
      {
        title: `${t(
          'pages.reports.retryInvoiceGeneration.transactions.total',
        )} (RON)`,
        key: 'id',
        width: 100,
        sorter: false,
        render: (v) => {
          const { parentProdQty, parentProdPrice } = v;
          return ((parentProdQty * parentProdPrice) / 100).toFixed(2);
        },
      },
      Column.actions(t('table.actions'), (record) => (
        <>
          <Tooltip title={t('actions.regenerateInvoice')}>
            <Button
              shape="circle"
              type="link"
              icon={<RedoOutlined />}
              onClick={() => handleRetryGeneration(record)}
            />
          </Tooltip>
          <Tooltip title={t('actions.cancelInvoiceGenerator')}>
            <Button
              shape="circle"
              type="link"
              icon={<StopOutlined />}
              onClick={() => handleCancelGeneration(record)}
            />
          </Tooltip>
        </>
      )),
    ],
    [t, handleRetryGeneration, handleCancelGeneration],
  );
  const [newColumns, setNewColumns] = useState(() => columns);

  const handleChangeHandler = useCallback(
    (first, filters, ...args) => {
      const julianDate = new Date(
        new Date(localStorage.getItem('julianStartDate')).toLocaleDateString(
          'en-US',
          {
            timeZone: 'Europe/Bucharest',
          },
        ),
      );

      const newValues = [
        'valStartDateA',
        'valEndDateA',
        'valStartDateB',
        'valEndDateB',
      ]
        .filter((field) => filters[field])
        .reduce((obj, field) => {
          return {
            ...obj,
            [`dateStatus${field.slice(-1)}`]: [0],
            [field]: [
              {
                from: Math.max(
                  -32768,
                  Math.ceil(
                    (new Date(filters[field][0]?.from) -
                      julianDate -
                      (new Date(filters[field][0]?.from).getTimezoneOffset() -
                        julianDate.getTimezoneOffset()) *
                        60 *
                        1000) /
                      (1000 * 3600 * 24),
                  ),
                ),
                to: Math.min(
                  32767,
                  Math.ceil(
                    (new Date(filters[field][0]?.to) -
                      julianDate -
                      (new Date(filters[field][0]?.to).getTimezoneOffset() -
                        julianDate.getTimezoneOffset()) *
                        60 *
                        1000) /
                      (1000 * 3600 * 24),
                  ),
                ),
              },
            ],
          };
        }, {});
      return handleChange(first, { ...filters, ...newValues }, ...args);
    },
    [handleChange],
  );

  const handleResize =
    (index) =>
    (e, { size }) => {
      setNewColumns((prevColumns) => {
        const nextColumns = [...prevColumns];
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width,
        };
        return nextColumns;
      });
    };

  useEffect(() => {
    setNewColumns(
      columns.map((col, index) => ({
        ...col,
        onHeaderCell: (column) => ({
          width: column.width,
          onResize: handleResize(index),
        }),
      })),
    );
  }, [columns]);

  return (
    <>
      <PageTitle />
      <Table
        rowKey="productId"
        columns={newColumns}
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChangeHandler}
        scroll={{ x: 700, y: 700 }}
        size="small"
        components={{
          header: {
            cell: ResizableHeaderCell,
          },
        }}
      />
    </>
  );
};

export default RetryInvoiceGenerationGrid;
