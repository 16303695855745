import React, { useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Col, Row, Space, Tabs } from 'antd';

import moment from 'moment';

import useForm, { FormContext } from '../../../hooks/useForm';

import FormWrapper from '../../FormWrapper';
import SaveButton from '../../buttons/SaveButton';
import CancelButton from '../../buttons/CancelButton';
import HolidaysSelection from './HolidaysSelection';

const TIME_FORMAT = 'HH:mm';
const WEEK_DAYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const EditScheduleForm = ({ schedule, onSubmit, onCancel }) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        timeStart: yup.string().required(t('errors.notEmpty')),
      }),
    [t],
  );

  const form = useForm({
    initialValue: schedule,
    schema: validationSchema,
    onSubmit,
    onCancel,
  });

  const { handleSubmit, value, input, checkbox, custom, setFormValue } = form;

  useEffect(() => {
    setFormValue(schedule);
  }, [setFormValue, schedule]);

  const { deleted } = value;

  const timeCustom = useCallback(
    (name) => ({
      name,
      getValue: () => {
        const mins = value[name];
        return mins != null
          ? moment().startOf('day').add(mins, 'minutes')
          : null;
      },
      setValue: (_, time) =>
        custom(name).setValue(
          name,
          time ? moment.duration(time.format(TIME_FORMAT)).asMinutes() : null,
        ),
      invalid: custom(name).invalid,
    }),
    [custom, value],
  );

  return (
    <>
      <FormContext.Provider value={form}>
        <form onSubmit={handleSubmit}>
          <FormWrapper>
            <FormWrapper.Single>
              <FormWrapper.Input
                label={t('entity.offers.schedule.name')}
                props={{ ...input('name'), disabled: deleted }}
              />

              <FormWrapper.TimeRangePicker
                label={t('entity.offers.schedule.selectTime')}
                props={{
                  start: timeCustom('timeStart'),
                  end: timeCustom('timeEnd'),
                  format: TIME_FORMAT,
                  placeholder: [
                    t('entity.offers.schedule.timeStart'),
                    t('entity.offers.schedule.timeEnd'),
                  ],
                  allowEmpty: [false, false],
                  disabled: deleted,
                }}
              />

              <Row>
                {WEEK_DAYS.map((day) => (
                  <Col xs={24} sm={12} md={8} lg={6} xl={6} key={day}>
                    <FormWrapper.CheckBox
                      label={t(`entity.offers.schedule.days.${day}`)}
                      props={{
                        ...checkbox(`day${capitalize(day)}`),
                        disabled: deleted,
                      }}
                    />
                  </Col>
                ))}
              </Row>
            </FormWrapper.Single>
            <FormWrapper.Double>
              <Tabs>
                <Tabs.TabPane
                  tab={t('entity.offers.schedule.holidays')}
                  key="holidays"
                >
                  <HolidaysSelection />
                </Tabs.TabPane>
              </Tabs>
            </FormWrapper.Double>
          </FormWrapper>

          <Space>
            {!deleted && <SaveButton />}
            <CancelButton onCancel={onCancel} />
          </Space>
        </form>
      </FormContext.Provider>
    </>
  );
};

export default EditScheduleForm;
