import React, { useContext, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Layout, Menu } from 'antd';
import {
  BarChartOutlined,
  BarcodeOutlined,
  ClusterOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  DollarOutlined,
  EuroOutlined,
  GoldOutlined,
  KeyOutlined,
  MessageOutlined,
  OrderedListOutlined,
  RedoOutlined,
  ScanOutlined,
  ScheduleOutlined,
  SecurityScanOutlined,
  SettingOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  UsergroupDeleteOutlined,
  UserOutlined,
} from '@ant-design/icons';

import AuthContext, { hasPermission } from '../components/auth';
import { AUTH_BASE_URI, AUTH_SERVICE_URI } from '../services/auth';

const Default = ({ children }) => {
  const { user } = useContext(AuthContext);
  const { permissions } = user;

  const history = useHistory();
  const { t } = useTranslation();
  const [isInvoiceEnabledString] = useState(window._env_.ENABLE_ERP);
  const isInvoiceEnabled = isInvoiceEnabledString === 'true';

  const { pathname } = useLocation();
  const selectedKeys = useMemo(
    () =>
      pathname.split('/').map((_, idx, arr) => arr.slice(0, idx + 1).join('/')),
    [pathname],
  );
  const defaultOpenKeys = useMemo(
    () =>
      pathname
        .split('/')
        .reverse()
        .slice(1)
        .reverse()
        .map((_, idx, arr) => arr.slice(0, idx + 1).join('/')),
    [pathname],
  );

  const handleSelect = ({ key }) => key && history.push(key);
  const handleLogout = () => {
    window.location.href = `${window._env_.API_BACKEND_URL}${AUTH_SERVICE_URI}${AUTH_BASE_URI}/logout`;
  };

  return (
    <>
      <Layout>
        <Layout.Header
          style={{
            position: 'fixed',
            zIndex: 1000,
            width: '100%',
            display: 'flex',
          }}
        >
          <div style={{ color: 'white', flexGrow: 1 }}>
            Urban GO! - BackOffice
          </div>
          <Menu theme="dark" mode="horizontal" style={{ minWidth: '200px' }}>
            <Menu.SubMenu
              style={{ float: 'right' }}
              key="account"
              title={user.email}
              popupOffset={[0, -10]}
            >
              <Menu.Item key="logout" onClick={handleLogout}>
                {t('navigation.logout')}
              </Menu.Item>
            </Menu.SubMenu>
          </Menu>
        </Layout.Header>

        <Layout>
          <Layout.Sider
            width={240}
            style={{
              height: '100vh',
              position: 'fixed',
              left: 0,
            }}
          >
            <Menu
              mode="inline"
              style={{
                height: '100vh',
                borderRight: 0,
                paddingTop: '64px',
                overflowY: 'auto',
                overflowX: 'hidden',
              }}
              defaultOpenKeys={defaultOpenKeys}
              selectedKeys={selectedKeys}
              onClick={handleSelect}
            >
              {hasPermission(permissions, [
                'SYS_SUPERUSER',
                'TAXONOMIES_PERMISSION',
                'OFFERS_PERMISSION',
                'SYS_VALIDATOR_EDIT',
                'ADMINISTRATION_PERMISSION',
                'ADMINISTRATION_ACCOUNTS_VIEW_PERMISSION',
                'ADMINISTRATION_USERS_VIEW_PERMISSION',
              ]) && (
                <>
                  <Menu.SubMenu
                    key="/admin"
                    icon={<SettingOutlined />}
                    title={t('startPage.administration')}
                  >
                    {hasPermission(permissions, [
                      'SYS_SUPERUSER',
                      'ADMINISTRATION_USERS_VIEW_PERMISSION',
                      'ADMINISTRATION_USERS_PERMISSION',
                    ]) && (
                      <Menu.Item key="/admin/users" icon={<UserOutlined />}>
                        {t('navigation.admin.users')}
                      </Menu.Item>
                    )}
                    {hasPermission(permissions, 'SYS_SUPERUSER') && (
                      <Menu.Item key="/admin/roles" icon={<KeyOutlined />}>
                        {t('navigation.admin.roles')}
                      </Menu.Item>
                    )}

                    {hasPermission(permissions, [
                      'SYS_SUPERUSER',
                      'TAXONOMIES_PERMISSION',
                      'SYS_VALIDATOR_EDIT',
                    ]) && (
                      <Menu.Item
                        key="/admin/taxonomies"
                        icon={<DatabaseOutlined />}
                      >
                        {t('navigation.admin.taxonomies')}
                      </Menu.Item>
                    )}

                    {hasPermission(permissions, [
                      'SYS_SUPERUSER',
                      'OFFERS_PERMISSION',
                    ]) && (
                      <Menu.Item key="/admin/offers" icon={<EuroOutlined />}>
                        {t('navigation.admin.offers')}
                      </Menu.Item>
                    )}

                    {hasPermission(permissions, [
                      'SYS_SUPERUSER',
                      'ADMINISTRATION_ACCOUNTS_VIEW_PERMISSION',
                      'ADMINISTRATION_ACCOUNTS_PERMISSION',
                    ]) && (
                      <Menu.Item key="/admin/accounts" icon={<TeamOutlined />}>
                        {t('navigation.admin.accounts')}
                      </Menu.Item>
                    )}
                    {hasPermission(permissions, [
                      'SYS_SUPERUSER',
                      'ADMINISTRATION_BLACKLIST_PERMISSION',
                    ]) && (
                      <Menu.Item
                        key="/admin/blacklist"
                        icon={<UsergroupDeleteOutlined />}
                      >
                        {t('navigation.admin.blacklist')}
                      </Menu.Item>
                    )}
                  </Menu.SubMenu>
                </>
              )}
              {hasPermission(permissions, ['SYS_SUPERUSER']) ? (
                <Menu.Item key="/messages" icon={<MessageOutlined />}>
                  {t('entity.messages._')}
                </Menu.Item>
              ) : null}
              {hasPermission(permissions, [
                'SYS_SUPERUSER',
                'HEAD_RETAILER',
              ]) ? (
                <Menu.Item key="/retail" icon={<GoldOutlined />}>
                  {t('entity.retail._')}
                </Menu.Item>
              ) : null}

              {hasPermission(permissions, [
                'SYS_SUPERUSER',
                'HEAD_SECTOR_INSPECTOR',
              ]) ? (
                <Menu.Item key="/inspectors" icon={<SecurityScanOutlined />}>
                  {t('entity.inspectors._')}
                </Menu.Item>
              ) : null}
              {hasPermission(permissions, [
                'SYS_SUPERUSER',
                'ENROLLMENT_PERMISSION',
              ]) ? (
                <Menu.Item key="/enrollment" icon={<BarcodeOutlined />}>
                  {t('pages.enrollment._')}
                </Menu.Item>
              ) : null}
              {hasPermission(permissions, [
                'SYS_SUPERUSER',
                'ORDERS_VIEW',
                'ORDERS_REVIEW',
                'ORDERS_PRINT',
                'ORDERS_CREATE',
              ]) ? (
                <Menu.Item key="/orders" icon={<OrderedListOutlined />}>
                  {t('pages.orders._')}
                </Menu.Item>
              ) : null}
              {hasPermission(permissions, [
                'SYS_SUPERUSER',
                'TVM_DASHBOARD_PERMISSION',
              ]) ? (
                <Menu.Item key="/tvm" icon={<DashboardOutlined />}>
                  {t('pages.tvmdashboard._')}
                </Menu.Item>
              ) : null}
              {hasPermission(permissions, [
                'SYS_SUPERUSER',
                'VIEW_VALIDATOR_MAINTENANCE',
              ]) ? (
                <Menu.Item
                  key="/dashboard-validators"
                  icon={<ClusterOutlined />}
                >
                  {t('pages.validators_dashboard._')}
                </Menu.Item>
              ) : null}
              {hasPermission(permissions, [
                'SYS_SUPERUSER',
                'REPORTS_PERMISSION',
                'REPORTS_MOBILE_SUMMARY_PERMISSION',
                'REPORTS_SALES_MOBILE_PERMISSION',
              ]) ? (
                <>
                  <Menu.SubMenu
                    key="/reports"
                    icon={<BarChartOutlined />}
                    title={t('pages.reports._')}
                  >
                    {hasPermission(permissions, [
                      'SYS_SUPERUSER',
                      'REPORTS_SALES_TVM_PERMISSION',
                    ]) ? (
                      <Menu.Item
                        key="/reports/sales-report-tvm"
                        icon={<DollarOutlined />}
                      >
                        {t('pages.reports.salesReportTvm._')}
                      </Menu.Item>
                    ) : null}
                    {hasPermission(permissions, [
                      'SYS_SUPERUSER',
                      'REPORTS_SALES_POS_PERMISSION',
                    ]) ? (
                      <Menu.Item
                        key="/reports/sales-report-pos"
                        icon={<DollarOutlined />}
                      >
                        {t('pages.reports.salesReport._')}
                      </Menu.Item>
                    ) : null}
                    {isInvoiceEnabled &&
                    hasPermission(permissions, [
                      'SYS_SUPERUSER',
                      'REPORTS_RETRY_INVOICE_GENERATION_PERMISSION',
                    ]) ? (
                      <Menu.Item
                        key="/reports/retry-invoice-generation"
                        icon={<RedoOutlined />}
                      >
                        {t('pages.reports.retryInvoiceGeneration._')}
                      </Menu.Item>
                    ) : null}
                    {hasPermission(permissions, [
                      'SYS_SUPERUSER',
                      'REPORTS_SALES_MOBILE_PERMISSION',
                    ]) ? (
                      <Menu.Item
                        key="/reports/sales-report-mobile"
                        icon={<DollarOutlined />}
                      >
                        {t('pages.reports.salesReportMobile._')}
                      </Menu.Item>
                    ) : null}
                    {hasPermission(permissions, [
                      'SYS_SUPERUSER',
                      'REPORTS_SALES_PORTAL_PERMISSION',
                    ]) ? (
                      <Menu.Item
                        key="/reports/sales-report-portal"
                        icon={<DollarOutlined />}
                      >
                        {t('pages.reports.salesReportPortal._')}
                      </Menu.Item>
                    ) : null}
                    {hasPermission(permissions, [
                      'SYS_SUPERUSER',
                      'REPORTS_SALES_ORDERS_PERMISSION',
                    ]) ? (
                      <Menu.Item
                        key="/reports/sales-report-orders"
                        icon={<DollarOutlined />}
                      >
                        {t('pages.reports.salesReportOrders._')}
                      </Menu.Item>
                    ) : null}
                    {hasPermission(permissions, [
                      'SYS_SUPERUSER',
                      'REPORTS_MOBILE_SUMMARY_PERMISSION',
                    ]) ? (
                      <Menu.Item
                        key="/reports/mobile-summary"
                        icon={<ScheduleOutlined />}
                      >
                        {t('pages.reports.mobileSummary._')}
                      </Menu.Item>
                    ) : null}
                    {hasPermission(permissions, [
                      'SYS_SUPERUSER',
                      'REPORTS_TRAVELER_VALIDATIONS_PERMISSION',
                    ]) ? (
                      <Menu.Item
                        key="/reports/traveler-validations"
                        icon={<ScanOutlined />}
                      >
                        {t('pages.reports.travelerValidations._')}
                      </Menu.Item>
                    ) : null}
                    {hasPermission(permissions, [
                      'SYS_SUPERUSER',
                      'REPORTS_EMV_DETAILS_PERMISSION',
                    ]) ? (
                      <Menu.Item
                        key="/reports/emv-details"
                        icon={<ScanOutlined />}
                      >
                        {t('pages.reports.emvDetails._')}
                      </Menu.Item>
                    ) : null}
                    {hasPermission(permissions, [
                      'SYS_SUPERUSER',
                      'REPORTS_RETRY_ENROLLED_PRODUCTS_PERMISSION',
                    ]) ? (
                      <Menu.Item
                        key="/reports/enrolled-products"
                        icon={<BarcodeOutlined />}
                      >
                        {t('pages.reports.enrolledProducts._')}
                      </Menu.Item>
                    ) : null}
                    {hasPermission(permissions, [
                      'SYS_SUPERUSER',
                      'REPORTS_LIST_PERMISSION',
                    ]) ? (
                      <Menu.Item
                        key="/reports/list"
                        icon={<UnorderedListOutlined />}
                      >
                        {t('pages.reports.list')}
                      </Menu.Item>
                    ) : null}
                    {hasPermission(permissions, [
                      'SYS_SUPERUSER',
                      'REPORTS_TRAVELES_PERMISSION',
                    ]) ? (
                      <Menu.Item
                        key="/reports/traveles"
                        icon={<ScanOutlined />}
                      >
                        {t('pages.reports.travelers.label')}
                      </Menu.Item>
                    ) : null}
                  </Menu.SubMenu>
                </>
              ) : null}
            </Menu>
          </Layout.Sider>
          <Layout
            style={{
              height: '100vh',
              minHeight: '400px',
              borderRight: 0,
              paddingTop: '64px',
              paddingLeft: '240px',
            }}
          >
            <div
              style={{
                padding: 24,
                margin: 0,
                height: '100%',
              }}
            >
              <Layout.Content
                style={{ background: 'white', minHeight: '100%', padding: 24 }}
              >
                {children}
              </Layout.Content>
            </div>
          </Layout>
        </Layout>
      </Layout>
      {window._env_.VERSION && (
        <div className="app-version">
          {new Date(
            (parseInt(window._env_.VERSION, 10) || 0) * 1000,
          ).toISOString()}
        </div>
      )}
    </>
  );
};

export default Default;
