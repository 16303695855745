import React, { useEffect, useCallback, useState, useContext } from 'react';
import { Col, Form, Input, Row, Select, Switch, message } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getUserTypes } from '../../../../services/taxonomies/user-types';
import { getPosDocTypes } from '../../../../services/taxonomies/doc-types';
import useDictionaries from '../../../../hooks/useDictionaries';
import { getInstitutions } from '../../../../services/taxonomies/institutions';
import { getSubdivisions } from '../../../../services/taxonomies/subdivisions';
import { getUserFilesById } from '../../../../services/admin/users';
import { getFiles } from '../../../../services/files';
import Span from '../../Span';
import { changeAttachmentsToOrder } from '../../../../services/pos/pos';
import POSContext from '../../POSContext';
import DatePickerLocale from '../../../DatePickerLocale';
import UserTypesFields from './UserTypesFields';

const dictionaries = {
  userTypes: getUserTypes,
  docTypes: getPosDocTypes,
};

const populateInstitutions = (userTypeId, userTypes, setInstitutions) => {
  const insts =
    userTypes.find((ut) => ut.id === userTypeId)?.institutions || [];

  if (insts.length) {
    getInstitutions({ pageSize: 100, criterias: { ids: insts } }).then(
      (res) => {
        setInstitutions(res.content);
      },
    );
  } else {
    setInstitutions([]);
  }
};

const populateSubdivisions = (institutionId, institutions, setSubdivisions) => {
  const subdivisions =
    institutions.find((i) => i.id === institutionId)?.subdivisions || [];
  if (subdivisions.length) {
    getSubdivisions({ pageSize: 100, criterias: { ids: subdivisions } }).then(
      (res) => {
        setSubdivisions(res.content);
      },
    );
  } else {
    setSubdivisions([]);
  }
};

const AccountDetailsForm = () => {
  const { t } = useTranslation();
  const { shift, order, setOrder } = useContext(POSContext);
  const { account } = order || {};
  const {
    userId,
    userProfileTypeId,
    institutionId,
    subdivisionId,
    metaData1,
    metaData2,
    attachments,
  } = account || {};
  const [form] = Form.useForm();
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current < moment().add(-1, 'day');
  };

  const [institutions, setInstitutions] = useState([]);
  const [subdivisions, setSubdivisions] = useState([]);
  const [userFiles, setUserFiles] = useState([]);

  const [{ docTypes, userTypes }] = useDictionaries(dictionaries);

  useEffect(() => {
    getUserFilesById(userId)
      .then((res) => {
        if (res.size > 0) {
          getFiles(res.map((item) => item.id)).then((res2) => {
            const usrFiles = res2.map((item) => {
              const uf = res.find((it) => it.id === item.id);
              const dt = docTypes.content.find((it) => it.id === uf.docTypeId);
              return {
                id: item.id,
                docName: dt?.name || '',
                fileName: item.name,
                expirationDate: uf?.expirationDate,
              };
            });
            setUserFiles(usrFiles);
          });
        }
      })
      .catch((err) => console.error(err));
  }, [userId, docTypes.content]);

  useEffect(() => {
    const attch = (attachments || []).map((item) => {
      const docType = docTypes.content.find((dt) => dt.id === item.documentId);
      return {
        ...item,
        docTypeName:
          docTypes.content.find((item1) => item.documentId === item1.id)
            ?.name || null,
        expirationDate: item.expirationDate
          ? moment(item.expirationDate)
          : null,
        verified: item.verified || false,
        hasExpirationDate: docType?.hasExpirationDate,
        dateChangeable: docType?.dateChangeable,
        durationType: docType?.durationType,
      };
    });
    const userTypeDocIds =
      userTypes.content.find((c) => c.id === userProfileTypeId)?.docTypes || [];
    const usrTypeDocs = docTypes.content
      .filter((dt) => userTypeDocIds.includes(dt.id))
      .filter((dt) => !dt.deleted);
    const formVal = {
      userTypeId: userProfileTypeId,
      institutionId,
      subdivisionId,
      metaData1,
      metaData2,
      files: attachments?.length
        ? attch
        : usrTypeDocs.map((r) => ({
            hasExpirationDate: r.hasExpirationDate,
            dateChangeable: r.dateChangeable,
            durationType: r.durationType,
            documentId: r.id,
            docTypeName: r.name,
            expirationDate: r.expirationDate ? moment(r.expirationDate) : null,
            verified: false,
          })),
    };
    form.setFieldsValue(formVal);
  }, [
    attachments,
    userTypes,
    form,
    userProfileTypeId,
    docTypes.content,
    institutionId,
    // temporary
    metaData1,
    metaData2,
    subdivisionId,
  ]);

  useEffect(() => {
    if (userProfileTypeId) {
      populateInstitutions(
        userProfileTypeId,
        userTypes.content,
        setInstitutions,
      );
    }
  }, [userTypes.content, userProfileTypeId]);

  useEffect(() => {
    if (institutionId) {
      populateSubdivisions(institutionId, institutions, setSubdivisions);
    }
  }, [institutionId, institutions, setSubdivisions]);

  const changeAttachments = useCallback(() => {
    const value = form.getFieldsValue();
    changeAttachmentsToOrder(shift, order, {
      ...value,
      files: value.files,
    })
      .then((res) => {
        setOrder(res);
      })
      .catch((err) => {
        console.error(err);
        message.error({
          content: err.inner._
            ? t(err.inner._)
            : 'Produsul nu a fost adaugat in cos',
          key: 'pos',
          duration: 5,
          className: 'card-message',
        });
      });
  }, [form, order, setOrder, shift, t]);

  const handleChangeUserType = useCallback(
    (val) => {
      form.resetFields();

      const userTypeDocIds =
        userTypes.content.find((c) => c.id === val)?.docTypes || [];
      const usrTypeDocs = docTypes.content
        .filter((dt) => userTypeDocIds.includes(dt.id))
        .filter((dt) => !dt.deleted);
      const formVal = {
        userTypeId: val,
        files: usrTypeDocs.map((r) => ({
          documentId: r.id,
          docTypeName: r.name,
          expirationDate: r.expirationDate ? moment(r.expirationDate) : null,
          hasExpirationDate: r.hasExpirationDate,
          dateChangeable: r.dateChangeable,
          durationType: r.durationType,
          verified: false,
        })),
      };
      form.setFieldsValue(formVal);
      populateInstitutions(val, userTypes.content, setInstitutions);
      setSubdivisions([]);
      changeAttachments();
    },
    [form, userTypes.content, docTypes.content, changeAttachments],
  );

  const handleChangeInstitution = useCallback(
    (val) => {
      setSubdivisions([]);
      const { userTypeId, files } = form.getFieldsValue();
      form.resetFields();
      form.setFieldsValue({ userTypeId, institutionId: val, files });
      populateSubdivisions(val, institutions, setSubdivisions);
      changeAttachments();
    },
    [form, institutions, changeAttachments],
  );

  const handleChangeSubdiv = useCallback(() => {
    changeAttachments();
  }, [changeAttachments]);

  return (
    <Form form={form} layout="vertical">
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
        <Col span={24}>
          <Form.Item name="userTypeId" label="Tip utilizator">
            <Select onChange={handleChangeUserType}>
              {userTypes.content.map((ut) => (
                <Select.Option
                  key={ut.id}
                  value={ut.id}
                  disabled={ut.disabled || ut.deleted}
                >
                  {ut.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
        <Col span={24}>
          <Form.Item name="institutionId" label="Institutia">
            <Select
              onChange={handleChangeInstitution}
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {institutions.map((i) => (
                <Select.Option
                  key={i.id}
                  value={i.id}
                  disabled={i.disabled || i.deleted}
                >
                  {i.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <UserTypesFields
        subdivisions={subdivisions}
        showColumns={
          userTypes?.content.find(
            (ut) => ut.id === form.getFieldValue(['userTypeId']),
          )?.showColumns
        }
        handleChangeSubdiv={handleChangeSubdiv}
        changeAttachments={changeAttachments}
      />

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
        <Col>
          {userFiles.map((item) => (
            <Row
              key={item.id}
              gutter={{ xs: 4, sm: 12, md: 20, lg: 32, xl: 8 }}
              style={{ padding: '0.5rem 0' }}
            >
              <Col span={6}>{item.docName}</Col>
              <Col span={10}>
                <Row>Data Expirare</Row>
                <Row>
                  <DatePickerLocale
                    disabled
                    format="DD.MM.YYYY"
                    value={
                      item.expirationDate
                        ? moment(item.expirationDate)
                        : item.expirationDate
                    }
                    style={{ width: '100%' }}
                  />
                </Row>
              </Col>
            </Row>
          ))}
          <Form.List name="files">
            {(fields) => {
              return (
                <>
                  {fields.map((field) => (
                    <Row
                      gutter={{ xs: 4, sm: 12, md: 20, lg: 32, xl: 8 }}
                      key={field.key}
                    >
                      <Col span={6}>
                        <Form.Item
                          noStyle
                          name={[field.name, 'fileId']}
                          fieldKey={[field.fieldKey, 'fileId']}
                        >
                          <Input type="hidden" />
                        </Form.Item>
                        <Form.Item
                          name={[field.name, 'docTypeName']}
                          fieldKey={[field.fieldKey, 'docTypeName']}
                        >
                          <Span />
                        </Form.Item>
                      </Col>
                      {!form.getFieldValue([
                        'files',
                        field.name,
                        'hasExpirationDate',
                      ]) && <Col span={10} />}
                      {form.getFieldValue([
                        'files',
                        field.name,
                        'hasExpirationDate',
                      ]) && (
                        <Col span={10}>
                          <Form.Item
                            {...field}
                            name={[field.name, 'expirationDate']}
                            fieldKey={[field.fieldKey, 'expirationDate']}
                            key={field.fieldKey}
                            label="Data Expirare"
                          >
                            <DatePickerLocale
                              disabled={
                                form.getFieldValue([
                                  'files',
                                  field.name,
                                  'durationType',
                                ]) &&
                                !form.getFieldValue([
                                  'files',
                                  field.name,
                                  'dateChangeable',
                                ])
                              }
                              format="DD.MM.YYYY"
                              style={{ width: '100%' }}
                              onChange={changeAttachments}
                              disabledDate={disabledDate}
                            />
                          </Form.Item>
                        </Col>
                      )}
                      <Col span={8}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'verified']}
                          fieldKey={[field.fieldKey, 'verified']}
                          key={field.fieldKey}
                          label="Am verificat actul"
                        >
                          <Switch
                            checked={
                              form.getFieldsValue(['files'])?.files[field.name]
                                ?.verified
                            }
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            onChange={changeAttachments}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                </>
              );
            }}
          </Form.List>
        </Col>
      </Row>
    </Form>
  );
};

export default AccountDetailsForm;
