import { search, get, post, put, remove } from '../../helpers/api';

export const AUTH_SERVICE_URI = '/offers';
export const AUTH_BASE_URI = '/api/v1/products';

export const getProducts = async (data) =>
  search(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}`, data);

export const getOrderedProducts = async (data) =>
  search(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}`, {
    ...data,
    criterias: { ...data.criterias, sort: [['position'], 'desc'] },
  });

export const getProductById = async (id) =>
  get(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${id}`);

export const createProduct = async (product) =>
  post(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}`, product);

export const updateProduct = async (product) =>
  put(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${product.id}`, product);

export const deleteProduct = async (product) =>
  remove(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/${product.id}`);

export const reorderProducts = async (data) =>
  post(`${AUTH_SERVICE_URI}${AUTH_BASE_URI}/reorder`, data);
