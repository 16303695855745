import { Space, Table } from 'antd';
import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import ViewItemIcon from '../../components/buttons/ViewItemIcon';
import PageTitle from '../../components/PageTitle';
import Column from '../../helpers/columns';
import useDatasource from '../../hooks/useDatasource';
import { Default } from '../../layouts';
import { getAccounts, downloadAll } from '../../services/accounts/accounts';
import { CustomerTypes } from '../../components/users/constants';
import ExportEntitiesButton from '../../components/buttons/ExportEntitiesButton';

const AccountsList = () => {
  const [t] = useTranslation();

  const { pagination, content, sort, criterias, handleChange } =
    useDatasource(getAccounts);

  const columns = useMemo(
    () => [
      Column.text('id', t('entity.account._singular'), {
        filter: true,
        width: 50,
      }),
      Column.text('userName', t('entity.account.name'), {
        sort: false,
        filter: true,
      }),
      Column.text('userIdentifier', t('entity.account.identifier'), {
        sort: false,
        filter: true,
      }),
      Column.text('cardsSerialNumber', t('entity.account.cardsSerialNumber'), {
        sort: false,
        filter: true,
      }),
      Column.dictionary(
        'customerType',
        t('entity.account.type._'),
        CustomerTypes,
        {
          filter: true,
          width: 150,
          dictLabel: ({ id }) => t(`entity.account.type.${id}`),
        },
      ),
      Column.bool('deleted', t('entity.account.cards.status._'), {
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('common.deleted.true'),
          t('common.deleted.false'),
          t('table.filter.unknown'),
        ],
      }),
      Column.date('createdAt', t('entity.account.createdAt'), {
        sort: false,
        filter: true,
      }),
      Column.actions(t('table.actions'), ({ id }) => (
        <>
          <ViewItemIcon path={`/admin/accounts/${id}`} />
        </>
      )),
    ],
    [t],
  );

  return (
    <Default>
      <PageTitle title={t('entity.account._plural')} />
      <PageTitle>
        <Space>
          <ExportEntitiesButton
            columns={columns}
            data={{
              sort,
              criterias,
            }}
            downloadFiles={downloadAll}
          />
        </Space>
      </PageTitle>
      <Table
        columns={columns}
        rowKey="id"
        // loading={loading}
        pagination={pagination}
        dataSource={content}
        onChange={handleChange}
        scroll={{ x: 700, y: 700 }}
        footer={() => `Total: ${pagination.total} elemente`}
      />
    </Default>
  );
};

export default AccountsList;
