import React from 'react';
import EditZoneForm from '../../../../components/zones/EditZoneForm';
import { useEditPage } from '../../../../hooks/useEditPage';
import { Default } from '../../../../layouts';
import { findOne, create, update } from '../../../../services/taxonomies/zones';

const initial = {};

const EditZone = () => {
  const [zone, handleSubmit, handleCancel] = useEditPage({
    initial,
    existent: findOne,
    goBackPath: '/admin/taxonomies/zones',
    onCreate: create,
    onUpdate: update,
  });

  return (
    <Default>
      {zone !== undefined && (
        <EditZoneForm
          zone={zone}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </Default>
  );
};

export default EditZone;
