import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import {
  Button,
  Checkbox,
  Col,
  Descriptions,
  Divider,
  Form,
  message,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from 'antd';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import useDatasource from '../../../../hooks/useDatasource';
import useDictionaries from '../../../../hooks/useDictionaries';

import { getTitlesById } from '../../../../services/accounts/accounts';

import Column from '../../../../helpers/columns';
import POSButton from '../../POSButton';
import POSContext from '../../POSContext';
import {
  blockCard,
  cancelOrder,
  changeCardToOrder,
  enableOrderCard,
  getAvailableProducts,
  getRouteById,
  moveOrderToNextStep,
  rechargeProductToOrder,
  removeProductFromOrder,
} from '../../../../services/pos/pos';

import { findAll } from '../../../../services/taxonomies/blacklist-reason';
import { getUserTypes } from '../../../../services/taxonomies/user-types';
import { getPosDocTypes } from '../../../../services/taxonomies/doc-types';
import { getProductById } from '../../../../services/offers/products';
import AccountDetailsForm from './AccountDetailsForm';
import DatePickerLocale from '../../../DatePickerLocale';
import {
  BlockingPlaces,
  UnblockingPlaces,
} from '../../../blacklist-reasons/constants';
import { ArticleTypes } from '../../../orders/Constants';
import Restriction from '../../Restriction';

const dictionaries = {
  blacklistReasons: findAll,
  userTypes: getUserTypes,
  docTypes: getPosDocTypes,
};
const AccountDetails = () => {
  const { t } = useTranslation();
  const [seeEnableCard, setSeeEnableCard] = useState(false);
  const [isStocksEnabledString] = useState(window._env_.STOCKS_ENABLE);
  const isStocksEnabled = isStocksEnabledString === 'true';

  const [form] = Form.useForm();
  const [productForm] = Form.useForm();
  const { shift, order, setOrder, cardBlocked, setCardBlocked } =
    useContext(POSContext);
  const [currentProduct, setCurrentProduct] = useState();
  const [accountCurrentTitles, setAccountCurrentTitles] = useState([]);
  const { account, card } = order;
  const [availableProductsList, setAvailableProductsList] = useState([]);
  const { attachments } = account;

  const [{ blacklistReasons, docTypes, userTypes }] =
    useDictionaries(dictionaries);

  const userType = useMemo(() => {
    return userTypes?.content?.find(
      (prod) => prod?.id === order?.account?.userProfileTypeId,
    );
  }, [userTypes?.content, order?.account?.userProfileTypeId]);

  dayjs.extend(customParseFormat);

  const [line, setLine] = useState(null);
  const [whereIsRestriction, setWhereIsRestriction] = useState([]);

  // eslint-disable-next-line arrow-body-style
  const disabledDate = (current) => {
    // Can not select days before today and today
    return (
      current < moment() ||
      Date.parse(current) > Date.parse(moment().add(90, 'days'))
    );
  };

  useEffect(() => {
    getAvailableProducts(shift, order)
      .then((prod) => {
        setAvailableProductsList(prod);
      })
      .catch((err) => {
        console.error(err);
        setAvailableProductsList([]);
      });
  }, [shift, order]);

  const fetchTitles = useCallback(
    (arg) =>
      getTitlesById(account.accountId, { ...arg, pageSize: 1000 }).then(
        (response) => {
          response?.content?.forEach((title) => {
            if (title.productType === 'PURSE') {
              // eslint-disable-next-line no-param-reassign
              title.quantity = `${parseFloat(title.purseValue / 100).toFixed(
                2,
              )} RON`;
            }
          });
          // eslint-disable-next-line no-unused-expressions
          response?.content?.length > 0
            ? setAccountCurrentTitles(response.content)
            : setAccountCurrentTitles([]);
          return response;
        },
      ),
    [account?.accountId],
  );
  const addProductToBasket = useCallback(
    (product) => {
      const hasRestriction = product?.payload?.some((item) =>
        item.restrictions.some(
          (rest) =>
            rest.restriction === 'LINE_GROUPS' &&
            (rest.value1 === 1 ||
              rest.value3 === 1 ||
              rest.value5 === 1 ||
              rest.value7 === 1),
        ),
      );
      if (hasRestriction) {
        const whereIsRestrictionData = product.payload.map((item) => {
          const results = [];
          item.restrictions.forEach((rest) => {
            if (
              rest.restriction === 'LINE_GROUPS' &&
              (rest.value1 === 1 ||
                rest.value3 === 1 ||
                rest.value5 === 1 ||
                rest.value7 === 1)
            ) {
              if (rest.value1 === 1) {
                results.push('value1');
              }
              if (rest.value3 === 1) {
                results.push('value3');
              }
              if (rest.value5 === 1) {
                results.push('value5');
              }
              if (rest.value7 === 1) {
                results.push('value7');
              }
            }
          });
          return results;
        });
        const titleFound = accountCurrentTitles.find(
          (title) => title.productId === product.id,
        );
        const ownedTitle = titleFound !== undefined ? titleFound : null;
        const now = new Date();
        const isDateAOutsideInterval =
          ownedTitle?.endDateA === null ||
          (ownedTitle?.endDateA != null &&
            new Date(ownedTitle?.endDateA) < now);
        const isDateBOutsideInterval =
          ownedTitle?.endDateB === null ||
          (ownedTitle?.endDateB != null &&
            new Date(ownedTitle?.endDateB) < now);
        const canAddTitleOnEveryLine =
          ownedTitle === null ||
          (isDateBOutsideInterval === true && isDateAOutsideInterval === true);
        getRouteById(titleFound.lines).then((res) => setLine(res));
        if (product?.category === 'PASS' && canAddTitleOnEveryLine === false) {
          setWhereIsRestriction(whereIsRestrictionData);
        }
        productForm.setFieldsValue({
          product: { ...product, count: 1 },
        });
      }
    },
    [accountCurrentTitles, productForm],
  );

  const rechargeProduct = useCallback(
    (value) => {
      const lineRestriction = productForm
        .getFieldValue(['product', 'payload'])
        .map((item) => {
          const foundItem = item.restrictions.find(
            (el) => el.restriction === 'LINE_GROUPS',
          );
          return foundItem; // Return the found item directly
        });

      const firstLineRestriction = lineRestriction.find(
        (item) => item !== undefined,
      );

      rechargeProductToOrder(shift, order, {
        id: value.id,
        count: value.count,
        restriction: firstLineRestriction,
        startDate: value.startDate,
      })
        .then((res) => {
          setOrder(res);
          setCurrentProduct();
          productForm.resetFields();
        })
        .catch((err) => {
          console.error(err);
          message.error({
            content: err.inner._
              ? t(err.inner._)
              : 'Produsul nu a fost adaugat in cos',
            key: 'pos',
            duration: 5,
            className: 'card-message',
          });
        });
    },
    [productForm, shift, order, setOrder, t],
  );

  const enableCard = useCallback(() => {
    setSeeEnableCard(false);
    enableOrderCard(shift, order)
      .then(() => {
        message.success({
          content: 'Cardul a fost deblocat',
          key: 'pos',
          className: 'card-message',
        });
        setCardBlocked(false);
      })
      .catch((err) => {
        console.error(err);
        notification.error({
          message: err.message,
        });
      });
  }, [setCardBlocked, order, shift]);

  const { content, handleChange } = useDatasource(fetchTitles);

  const changeCard = useCallback(() => {
    changeCardToOrder(shift, order)
      .then((res) => {
        setOrder(res);
      })
      .catch((err) => {
        console.error(err);
        message.error({
          content: err.inner._
            ? t(err.inner._)
            : 'Produsul nu a fost adaugat in cos',
          key: 'pos',
          duration: 1,
          className: 'card-message',
        });
      });
  }, [order, setOrder, shift, t]);

  useEffect(() => {
    const arr = (attachments || []).map((item) => ({
      ...item,
      docTypeName: !docTypes.loading
        ? docTypes.content.find((item1) => item.documentId === item1.id).name
        : null,
      expirationDate: item.expirationDate ? moment(item.expirationDate) : null,
    }));
    const type = userTypes.content.find(
      // eslint-disable-next-line eqeqeq
      (c) => c.id == account.userProfileTypeId,
    );
    const docs = type?.docTypes || [];

    const res = docTypes.content.filter((dt) => docs.includes(dt.id));
    form.setFieldsValue({
      userTypeId: account.userProfileTypeId,
      files:
        attachments && attachments.length
          ? arr
          : res
              .filter((r) => !r.deleted)
              .map((r) => ({
                hasExpirationDate: r.hasExpirationDate,
                dateChangeable: r.dateChangeable,
                durationType: r.durationType,
                documentId: r.id,
                ducumentName: null,
                docTypeName: r.name,
                initial: false,
                expirationDate: r.expirationDate
                  ? moment(r.expirationDate)
                  : null,
              })),
    });
  }, [docTypes, attachments, form, userTypes, account]);

  const handleSeeEnableCard = useCallback(() => setSeeEnableCard(true), []);

  const handleExtendProduct = useCallback(
    (product) => {
      const req = {
        id: product.id,
        count: product?.productType === 'PURSE' ? 100 : 1,
      };

      rechargeProductToOrder(shift, order, req)
        .then((res) => {
          setOrder(res);
        })
        .catch((err) => {
          console.error(err);
          message.error({
            content: err.inner._
              ? t(err.inner._)
              : 'Produsul nu a fost adaugat in cos',
            key: 'pos',
            duration: 5,
            className: 'card-message',
          });
        });
    },
    [shift, order, setOrder, t],
  );

  const handleChangeProduct = useCallback(
    (product) => {
      getProductById(product.productId)
        .then((value) => {
          if (value.validityStartType === 'FUTURE') {
            const selectedTitle = availableProductsList
              .map((item) => item.products)
              .flat()
              .find((el) => {
                const matchingTitleData = el.payload.find(
                  (payloadEl) => payloadEl.id === product.productId,
                );
                return matchingTitleData !== undefined;
              });
            setCurrentProduct(value);
            addProductToBasket(selectedTitle);
            productForm.setFieldsValue({
              id: product.id,
              count: value.category === 'PASS' ? null : 1,
              product: selectedTitle,
            });
          } else {
            handleExtendProduct(product);
          }
        })
        .catch((err) => {
          console.error(err);
          message.error({
            content: err.inner._
              ? t(err.inner._)
              : 'Produsul nu a fost adaugat in cos',
            key: 'pos',
            duration: 5,
            className: 'card-message',
          });
        });
    },
    [
      availableProductsList,
      addProductToBasket,
      productForm,
      handleExtendProduct,
      t,
    ],
  );

  const existentColumns = useMemo(
    () => [
      Column.text(
        'productName',
        t('entity.account.transportTitle.product_name'),
        {
          filter: true,
        },
      ),
      Column.text(
        'productType',
        t('entity.account.transportTitle.product_types._'),
        {
          filter: true,
        },
      ),
      Column.text(
        'validPeriod',
        t('entity.account.transportTitle.validPeriod'),
        {
          sort: false,
          width: 500,
        },
      ),
      Column.text('quantity', t('entity.account.transportTitle.quantity'), {
        sort: false,
      }),
      {
        title: 'Actiuni',
        key: 'actions',
        width: 1,
        render: (_, row) => (
          <Space>
            <Button type="danger" onClick={() => handleChangeProduct(row)}>
              Prelungire
            </Button>
          </Space>
        ),
      },
    ],
    [handleChangeProduct, t],
  );

  const handleClickNewProducts = useCallback(() => {
    setOrder({ ...order, account: { ...order.account, userId: null } });
  }, [order, setOrder]);

  const removeProductFromBasket = useCallback(
    (product) => {
      removeProductFromOrder(shift, order, product)
        .then((res) => {
          setOrder(res);
        })
        .catch((err) => {
          console.error(err);
          message.error({
            content: 'Produsul nu a fost sters in cos',
            key: 'pos',
            duration: 5,
            className: 'card-message',
          });
        });
    },
    [shift, order, setOrder],
  );

  const columns = useMemo(
    () => [
      { title: 'Name', dataIndex: 'name', key: 'name' },
      {
        title: 'Pret',
        width: 150,
        key: 'price',
        dataIndex: 'price',
        render: (_, row) =>
          row.category === 'PURSE'
            ? (row.qty * 0.01).toFixed(2)
            : (row.price * 0.01).toFixed(2),
      },
      {
        title: 'Cantitate',
        width: 150,
        dataIndex: 'qty',
        key: 'count',
        render: (_, row) => (row.category === 'PURSE' ? '-' : row.qty),
      },
      {
        title: 'Pret total',
        width: 150,
        key: 'totalPrice',
        render: (_, row) => <>{(row.qty * row.price * 0.01).toFixed(2)}</>,
      },
      {
        title: 'Perioada de valabilitate',
        width: 200,
        dataIndex: 'validPeriod',
        key: 'validPeriod',
      },
      {
        title: 'Actiuni',
        key: 'actions',
        width: 1,
        render: (_, row) => (
          <Space>
            <Button type="primary" disabled>
              Configurare
            </Button>
            <Button type="danger" onClick={() => removeProductFromBasket(row)}>
              Sterge
            </Button>
          </Space>
        ),
      },
    ],
    [removeProductFromBasket],
  );

  const handleCancelOrder = useCallback(() => {
    cancelOrder(shift, order)
      .then((res) => {
        setOrder(res);
      })
      .catch((err) => {
        console.error(err);
        message.error({
          content: 'Eroare la anulare comanda',
          key: 'pos',
          duration: 5,
          className: 'card-message',
        });
      });
  }, [shift, order, setOrder]);

  const reason = useRef(null);
  const oldCardPosesion = useRef(false);

  const handleBlockCard = useCallback(() => {
    Modal.confirm({
      title: 'Blocare card',
      content: (
        <>
          <div>
            <Typography.Paragraph>
              {t('pages.stocks.reason')} :
            </Typography.Paragraph>
            <Select
              style={{ width: '100%' }}
              onChange={(val) => {
                reason.current = val;
              }}
              defaultValue={null}
            >
              {(blacklistReasons.content || [])
                .filter(
                  (it) =>
                    it.blockingPlaces &&
                    it.blockingPlaces.includes(BlockingPlaces.POS),
                )
                .map((res) => (
                  <Select.Option key={res.id} value={res.id}>
                    {res.name}
                  </Select.Option>
                ))}
            </Select>
          </div>
          <br />
          <div>
            {isStocksEnabled && ArticleTypes.Mifare1K === card?.cardTypeId && (
              <>
                {t('pages.stocks.hasCard')}
                <Checkbox
                  onChange={(e) => {
                    oldCardPosesion.current = e.target.checked;
                  }}
                />
              </>
            )}
          </div>
        </>
      ),
      onOk() {
        blockCard(shift, order, reason.current, oldCardPosesion.current)
          .then(() => {
            message.success({
              content: 'Cardul a fost blocat',
              key: 'pos',
              className: 'card-message',
            });
            setOrder(null);
          })
          .catch((err) => {
            console.error(err);
            message.error({
              content: 'Eroare la blocare card',
              key: 'pos',
              duration: 5,
              className: 'card-message',
            });
          });
      },
      onCancel() {},
    });
  }, [
    order,
    setOrder,
    shift,
    blacklistReasons,
    isStocksEnabled,
    card?.cardTypeId,
    t,
  ]);

  const handleExchangeCard = useCallback(() => {
    Modal.confirm({
      title: 'Schimba card',
      content: (
        <div>
          {isStocksEnabled && ArticleTypes.Mifare1K === card?.cardTypeId && (
            <>
              {t('pages.stocks.hasCard')}
              <Checkbox
                onChange={(e) => {
                  oldCardPosesion.current = e.target.checked;
                }}
              />
            </>
          )}
        </div>
      ),
      onOk() {
        changeCardToOrder(shift, order, oldCardPosesion.current)
          .then((res) => {
            setOrder(res);
          })
          .catch((err) => {
            console.error(err);
            message.error({
              content: err.inner._
                ? t(err.inner._)
                : 'Produsul nu a fost adaugat in cos',
              key: 'pos',
              duration: 1,
              className: 'card-message',
            });
          });
      },
      onCancel() {},
    });
  }, [order, setOrder, shift, isStocksEnabled, card?.cardTypeId, t]);

  const handleNext = useCallback(() => {
    moveOrderToNextStep(shift, order)
      .then((res) => {
        setOrder(res);
      })
      .catch((err) => {
        console.error(err);
        message.error({
          content: err.inner._
            ? t(err.inner._)
            : 'Eroare la trecere pas urmator',
          key: 'pos',
          duration: 1,
          className: 'card-message',
        });
      });
  }, [shift, order, setOrder, t]);

  return (
    <div className="pos">
      <div className="pos__wrapper">
        <Descriptions bordered>
          <Descriptions.Item label="Nume, Prenume">
            {account.userFullname}
          </Descriptions.Item>
          <Descriptions.Item label="CNP">
            {account.userIdentityCard}
          </Descriptions.Item>
          <Descriptions.Item label="Numar card">
            {card.cardLogicalId}
          </Descriptions.Item>

          <Descriptions.Item label="Persoana fizica">
            {account.userCustomerType}
          </Descriptions.Item>
          <Descriptions.Item label="Tip utilizator">
            {userType?.name}
          </Descriptions.Item>
          <Descriptions.Item label="Tip cont">&nbsp;</Descriptions.Item>
        </Descriptions>
        <Row
          gutter={{ xs: 8, sm: 16, md: 24, lg: 28, xl: 0 }}
          style={{ padding: '2rem 0' }}
        >
          <Col xs={{ span: 5 }} lg={{ span: 5, offset: 0 }}>
            {account && <AccountDetailsForm />}
          </Col>
          <Col xs={{ span: 7 }} lg={{ span: 7, offset: 4 }}>
            {!cardBlocked && (
              <POSButton
                title="Selectare produse"
                onClick={handleClickNewProducts}
                color="green"
              />
            )}
          </Col>
          <Col xs={{ offset: 12, span: 12 }} lg={{ span: 4, offset: 4 }}>
            {!cardBlocked ? (
              <Row gutter={[0, 8]}>
                <Col span={24}>
                  <POSButton
                    title="Preschimbarea card"
                    onClick={
                      isStocksEnabled &&
                      ArticleTypes.Mifare1K === card?.cardTypeId
                        ? handleExchangeCard
                        : changeCard
                    }
                    color="green"
                  />
                </Col>
                <Col span={24}>
                  <POSButton
                    title="Blocare card"
                    onClick={handleBlockCard}
                    color="red"
                  />
                </Col>
              </Row>
            ) : (
              <Col span={24}>
                <POSButton
                  hidden={
                    !(
                      blacklistReasons.content.find(
                        (item) => item.id === order.card.cardBlacklistReason,
                      )?.unblockingPlaces || []
                    ).includes(UnblockingPlaces.POS)
                  }
                  title="Deblocare card"
                  onClick={handleSeeEnableCard}
                  color="green"
                />
              </Col>
            )}
          </Col>
        </Row>

        <Row
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}
          style={{ padding: '2rem 0' }}
        >
          <Col span={12}>
            <Divider>Produse selectate</Divider>
            <div className="pos__table">
              <Table
                dataSource={order.products}
                rowKey="id"
                columns={columns}
                pagination={false}
              />
            </div>
          </Col>
          <Col span={12}>
            <Divider>Titluri existente</Divider>
            <div className="pos__table">
              <Table
                columns={existentColumns}
                rowKey="id"
                // loading={loading}
                dataSource={content}
                onChange={handleChange}
                pagination={false}
              />
            </div>
          </Col>
        </Row>
        <Modal
          title="Deblocarea cardului"
          visible={seeEnableCard}
          closable={false}
          footer={[
            <Button key="submit" type="primary" onClick={enableCard}>
              Deblochează cardul
            </Button>,
            <Button
              key="close"
              type="primary"
              onClick={() => {
                setSeeEnableCard(false);
              }}
            >
              Inchide
            </Button>,
          ]}
        >
          Doriți să deblocați cardul
        </Modal>
      </div>
      <div className="pos__actions">
        <div>
          <POSButton
            title="Anulare"
            color="red"
            size="small"
            onClick={() => {
              setCardBlocked(false);
              handleCancelOrder();
            }}
          />
        </div>

        <div>
          {!cardBlocked && (
            <POSButton
              title="Mai departe"
              color="green"
              size="small"
              onClick={handleNext}
            />
          )}
        </div>
      </div>
      <Modal
        title="Configurare"
        visible={currentProduct}
        onOk={() => {
          productForm
            .validateFields()
            .then(rechargeProduct)
            .catch((info) => {
              console.error('Validate Failed:', info);
            });
        }}
        onCancel={() => {
          setCurrentProduct();
          productForm.resetFields();
        }}
      >
        <Form form={productForm} layout="vertical">
          <Form.Item hidden name={['id']} />
          <Form.Item hidden name={['count']} />
          {currentProduct && currentProduct.validityStartType === 'FUTURE' && (
            <Form.Item noStyle name={['startDate']}>
              <DatePickerLocale
                format="DD.MM.YYYY HH:mm"
                showTime={{
                  defaultValue: dayjs('00:00:00', 'HH:mm'),
                }}
                style={{ width: '100%' }}
                disabledDate={disabledDate}
              />
            </Form.Item>
          )}
          <Form.List name={['product', 'payload']}>
            {(fields) =>
              fields.map((field) => (
                <Form.List name={[field.name, 'restrictions']} key={field.key}>
                  {(fields2) =>
                    fields2.map((field2) => (
                      <Restriction
                        key={field2.key}
                        form={productForm}
                        line={line?.id}
                        whereIsRestriction={whereIsRestriction}
                        name={[field.name, 'restrictions', field2.name]}
                      />
                    ))
                  }
                </Form.List>
              ))
            }
          </Form.List>
        </Form>
      </Modal>
    </div>
  );
};

export default AccountDetails;
