import { useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { notification } from 'antd';

import { useRouteParams } from './useRouteParams';
import { removeDiacritics } from '../helpers/diacritics-remover.ts';

export function useEditPage({
  initial,
  existent,
  goBackPath,

  onInvalid, // or handleCancel will be called
  onFailed,
  onCreate,
  onUpdate,
  goBackOnSave,
}) {
  const { id, isNew, isInvalid } = useRouteParams();
  const { t } = useTranslation();
  const history = useHistory();

  const [entity, setEntity] = useState(undefined);

  const handleFail = useCallback(
    (err) => {
      notification.error({
        message: t('actions.loadFailed', err),
      });
    },
    [t],
  );

  const handleCancel = useCallback(() => {
    if (typeof goBackPath === 'function') {
      goBackPath();
    } else {
      history.push(goBackPath);
    }
  }, [goBackPath, history]);

  useEffect(() => {
    if (isInvalid) {
      if (typeof onInvalid === 'function') {
        onInvalid();
      } else {
        handleCancel();
      }
    } else {
      (id ? existent(id) : Promise.resolve(initial))
        .then((res) => setEntity(res))
        .catch((err) => {
          if (typeof onFailed === 'function') {
            onFailed(err);
          } else {
            handleFail(err);
          }
        });
    }
  }, [
    id,
    isNew,
    isInvalid,
    initial,
    existent,
    onInvalid,
    onFailed,
    handleCancel,
    handleFail,
  ]);

  const handleSubmit = useCallback(
    (value) =>
      (isNew ? onCreate(value) : onUpdate({ ...value, id }))
        .then((response) => {
          notification.success({
            message: t('actions.saveSuccessful'),
          });

          if (goBackOnSave) {
            if (typeof goBackPath === 'function') {
              goBackPath();
            } else {
              history.push(goBackPath);
            }
          } else if (isNew) {
            const { id: entityId } = response;
            const {
              location: { pathname },
            } = history;

            history.replace(
              pathname
                .split('/')
                .slice(0, pathname.endsWith('/clone') ? -2 : -1)
                .concat([entityId])
                .join('/'),
            );
          } else {
            return existent(id).then((res) => setEntity(res));
          }
          return Promise.resolve();
        })
        .catch((msg) => {
          console.error(msg);
          const { inner } = msg || {};
          const { _: messageCode } = inner || {};
          if (
            messageCode === `errors.severalPeopleSameEmail` ||
            messageCode === `errors.severalPeopleSameIdentityCard` ||
            messageCode === `errors.multipleCardsSameCardLogicalId` ||
            messageCode ===
              `errors.startDateOrEndDateIntersectsWithValidPeriod` ||
            messageCode === `errors.bothDatesFilled` ||
            messageCode === `errors.cardLogicalIdsDeleted` ||
            messageCode === 'errors.disableCardLogicalIdsNotExists' ||
            messageCode === 'errors.disableCardsNotIssued' ||
            messageCode === 'errors.rechargeCardsStatus'
          ) {
            const blob = removeDiacritics(t(messageCode, inner));
            const url = window.URL.createObjectURL(
              new Blob([blob], {
                type: 'application/json',
              }),
            );
            const a = document.createElement('a');
            a.href = url;
            a.target = '_blank';
            a.download = 'document.csv';
            document.body.appendChild(a);
            a.click();
            a.remove();
          }
          notification.error({
            message: messageCode
              ? t(messageCode, inner).replace('\n', '').replaceAll('\n', ', ')
              : t('actions.saveFailed'),
          });
          return Promise.reject(msg);
        }),
    [
      isNew,
      onCreate,
      onUpdate,
      id,
      t,
      goBackOnSave,
      history,
      goBackPath,
      existent,
    ],
  );

  const result = useMemo(
    () => [entity, handleSubmit, handleCancel],
    [entity, handleSubmit, handleCancel],
  );

  return result;
}
