import React, { useContext, useMemo, useCallback, useEffect } from 'react';
import moment from 'moment';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import Column from '../../helpers/columns';
import { FormContext } from '../../hooks/useForm';
import { getTitlesByParentId } from '../../services/accounts/accounts';
import useDatasource, { filterDate } from '../../hooks/useDatasource';

const ActivationType = {
  content: [{ id: '0' }, { id: '1' }, { id: '2' }],
  loading: false,
  error: false,
};
export const ProductCategories = {
  content: [
    { id: 'PASS' },
    { id: 'PURSE' },
    { id: 'TICKET' },
    { id: 'OPERATOR' },
  ],
  loading: false,
  error: false,
};

// cicd
const TitleDescendantsList = () => {
  const { t } = useTranslation();

  const form = useContext(FormContext);
  const { value } = form;
  const { id } = value;

  const { pagination, content, handleChange } = useDatasource((arg) =>
    getTitlesByParentId(id, arg),
  );

  useEffect(() => {
    if (content && content.length > 0) {
      localStorage.setItem('julianStartDate', content[0].julianStartDate);
    }
  }, [content]);

  const columns = useMemo(
    () => [
      Column.text('productId', t('entity.account.transportTitle.product_id'), {
        filter: true,
        width: 100,
      }),
      Column.text(
        'productName',
        t('entity.account.transportTitle.product_name'),
        {
          filter: true,
        },
      ),
      Column.dictionary(
        'productType',
        t('entity.account.transportTitle.product_types._'),
        ProductCategories,
        {
          width: 150,
          filter: true,
          sort: true,
          dictLabel: (entry) =>
            t(`entity.account.transportTitle.product_types.${entry.id}`),
        },
      ),
      Column.text('accountId', t('entity.account._singular'), {
        filter: true,
        sort: false,
        width: 100,
      }),
      Column.text('userName', t('entity.account.name'), {
        filter: true,
        sort: false,
      }),
      Column.text('userIdentifier', t('entity.account.identifier'), {
        filter: true,
        sort: false,
      }),
      Column.text('quantity', t('entity.account.transportTitle.quantity'), {
        sort: false,
      }),
      Column.dictionary(
        'dateStatusA',
        t('components.salesReport.dateStatus.A'),
        ActivationType,
        {
          width: 150,
          filter: true,
          dictLabel: (entry) =>
            t(`components.salesReport.dateStatus.${entry.id}`),
        },
      ),
      {
        title: t('components.salesReport.valStaDateA'),
        key: 'valStaDateA',
        dataIndex: 'valStaDateA',
        width: 150,
        sorter: true,
        ...filterDate({ title: t('components.salesReport.valStaDateA') }),
        render: (val, record) => {
          // eslint-disable-next-line react/destructuring-assignment
          const date = new Date(record.julianStartDate);
          // eslint-disable-next-line react/destructuring-assignment
          date.setDate(date.getDate() + record.valStaDateA - 1);
          return (
            <>
              {
                // eslint-disable-next-line react/destructuring-assignment
                record.valStaDateA ? moment(date).format('YYYY-MM-DD') : ''
              }
            </>
          );
        },
      },
      {
        title: t('components.salesReport.valEndDateA'),
        key: 'valEndDateA',
        dataIndex: 'valEndDateA',
        width: 150,
        sorter: true,
        ...filterDate({ title: t('components.salesReport.valEndDateA') }),
        render: (val, record) => {
          // eslint-disable-next-line react/destructuring-assignment
          const date = new Date(record.julianStartDate);
          // eslint-disable-next-line react/destructuring-assignment
          date.setDate(date.getDate() + record.valEndDateA - 1);
          // eslint-disable-next-line react/destructuring-assignment
          return (
            <>
              {
                // eslint-disable-next-line react/destructuring-assignment
                record.valEndDateA ? moment(date).format('YYYY-MM-DD') : ''
              }
            </>
          );
        },
      },
      Column.dictionary(
        'dateStatusB',
        t('components.salesReport.dateStatus.B'),
        ActivationType,
        {
          width: 150,
          filter: true,
          dictLabel: (entry) =>
            t(`components.salesReport.dateStatus.${entry.id}`),
        },
      ),
      {
        title: t('components.salesReport.valStaDateB'),
        key: 'valStaDateB',
        dataIndex: 'valStaDateB',
        width: 150,
        sorter: true,
        ...filterDate({ title: t('components.salesReport.valStaDateB') }),
        render: (val, record) => {
          // eslint-disable-next-line react/destructuring-assignment
          const date = new Date(record.julianStartDate);
          // eslint-disable-next-line react/destructuring-assignment
          date.setDate(date.getDate() + record.valStaDateB - 1);
          return (
            <>
              {
                // eslint-disable-next-line react/destructuring-assignment
                record.valStaDateB ? moment(date).format('YYYY-MM-DD') : ''
              }
            </>
          );
        },
      },
      {
        title: t('components.salesReport.valEndDateB'),
        key: 'valEndDateB',
        dataIndex: 'valEndDateB',
        width: 150,
        sorter: true,
        ...filterDate({ title: t('components.salesReport.valEndDateB') }),
        render: (val, record) => {
          // eslint-disable-next-line react/destructuring-assignment
          const date = new Date(record.julianStartDate);
          // eslint-disable-next-line react/destructuring-assignment
          date.setDate(date.getDate() + record.valEndDateB - 1);
          // eslint-disable-next-line react/destructuring-assignment
          return (
            <>
              {
                // eslint-disable-next-line react/destructuring-assignment
                record.valEndDateB ? moment(date).format('YYYY-MM-DD') : ''
              }
            </>
          );
        },
      },
      Column.bool('deleted', t('entity.account.transportTitle.deleted._'), {
        filter: true,
        inverted: true,
        labels: [
          t('table.filter.all'),
          t('entity.account.transportTitle.deleted.false'),
          t('entity.account.transportTitle.deleted.true'),
          t('table.filter.unknown'),
        ],
      }),
    ],
    [t],
  );
  const handleChangeHandler = useCallback(
    (first, filters, ...args) => {
      const julianDate = new Date(
        new Date(localStorage.getItem('julianStartDate')).toLocaleDateString(
          'en-US',
          {
            timeZone: 'Europe/Bucharest',
          },
        ),
      );

      const newValues = [
        'valStaDateA',
        'valEndDateA',
        'valStaDateB',
        'valEndDateB',
      ]
        .filter((field) => filters[field])
        .reduce((obj, field) => {
          return {
            ...obj,
            [field]: [
              {
                from: Math.max(
                  -32768,
                  Math.ceil(
                    (new Date(filters[field][0]?.from) -
                      julianDate -
                      (new Date(filters[field][0]?.from).getTimezoneOffset() -
                        julianDate.getTimezoneOffset()) *
                        60 *
                        1000) /
                      (1000 * 3600 * 24),
                  ),
                ),
                to: Math.min(
                  32767,
                  Math.ceil(
                    (new Date(filters[field][0]?.to) -
                      julianDate -
                      (new Date(filters[field][0]?.to).getTimezoneOffset() -
                        julianDate.getTimezoneOffset()) *
                        60 *
                        1000) /
                      (1000 * 3600 * 24),
                  ),
                ),
              },
            ],
          };
        }, {});
      return handleChange(first, { ...filters, ...newValues }, ...args);
    },
    [handleChange],
  );

  const contentWithProdType = useMemo(
    () =>
      content.map((title) => ({
        ...title,
        prodTypeName: t(
          `entity.account.transportTitle.product_types.${title.productType}`,
        ),
      })),
    [content, t],
  );

  return (
    <Table
      columns={columns}
      rowKey="id"
      // loading={loading}
      pagination={pagination}
      dataSource={contentWithProdType}
      onChange={handleChangeHandler}
      scroll={{ x: 700, y: 700 }}
    />
  );
};
export default TitleDescendantsList;
