import React, { useState, useEffect, useMemo } from 'react';

import { getCurrentShift, getCurrentOrder } from '../../services/pos/pos';

import AppLoader from '../auth/AppLoader';

const POSContext = React.createContext(null);

export const POSContextWrapper = ({ children }) => {
  const [loaded, setLoaded] = useState(false);

  const [shift, setShift] = useState(null);
  const [order, setOrder] = useState(null);
  const [cardBlocked, setCardBlocked] = useState(false);

  useEffect(() => {
    setLoaded(false);

    getCurrentShift()
      .then((currentShift) => {
        setShift(currentShift);
        return getCurrentOrder(currentShift).then((currentOrder) => {
          if (currentOrder.card.deleted) {
            setCardBlocked(currentOrder.card.cardPhysicalId);
          }
          setOrder(currentOrder);
        });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoaded(true));
  }, []);

  const contextValue = useMemo(
    () => ({ shift, setShift, order, setOrder, cardBlocked, setCardBlocked }),
    [shift, order, cardBlocked],
  );

  return (
    <POSContext.Provider value={contextValue}>
      {loaded ? children : <AppLoader />}
    </POSContext.Provider>
  );
};

export default POSContext;
