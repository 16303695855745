/* eslint-disable no-underscore-dangle */
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';

import _fp from 'lodash/fp';
import i18n from 'i18next';

const ACTION_SET = Symbol('ACTION_SET');
const ACTION_TOUCH = Symbol('ACTION_TOUCH');
const ACTION_RESET = Symbol('ACTION_RESET');
const ACTION_VALIDATING = Symbol('ACTION_VALIDATING');
const ACTION_VALIDATED = Symbol('ACTION_VALIDATED');
const ACTION_SUBMITTING = Symbol('ACITON_SUBMITTING');
const ACTION_SUBMIT_SUCCESS = Symbol('ACITON_SUBMITTED');
const ACTION_SUBMIT_FAILED = Symbol('ACTION_SUBMIT_FAILED');

const DEFAULT_INITIAL_VALUE = {};
const DEFAULT_VALIDATE_ON_CHANGE = false;

const formInitialState = {
  value: DEFAULT_INITIAL_VALUE,
  errorMessages: null,

  isDirty: false,
  isSubmitting: false,
  isSubmitted: false,

  isValidating: false,
  isValid: true,

  _fieldsUpdated: [],
  _hooks: null,
};

const formReducer = (state, action) => {
  switch (action.type) {
    case ACTION_RESET:
      return {
        ...formInitialState,
        value: action.payload,
      };

    case ACTION_SET:
      // eslint-disable-next-line no-case-declarations
      const fieldsUpdated = Object.keys(action.payload);
      return {
        ...state,
        // eslint-disable-next-line no-underscore-dangle
        _fieldsUpdated: (state._fieldsUpdated || []).concat(fieldsUpdated),
        value: Object.entries(action.payload).reduce(
          (acc, [field, value]) => _fp.set(field, value, acc),
          state.value,
        ),
        isDirty: true,
        isSubmitted: false,
      };

    case ACTION_TOUCH:
      // eslint-disable-next-line no-case-declarations
      const fieldsToucherd = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];

      return {
        ...state,
        // eslint-disable-next-line no-underscore-dangle
        _fieldsUpdated: (state._fieldsUpdated || []).concat(fieldsToucherd),
        isDirty: true,
        isSubmitted: false,
      };

    case ACTION_VALIDATING:
      return {
        ...state,
        isValidating: true,
        isValid: false,
      };

    case ACTION_VALIDATED:
      return {
        ...state,
        _fieldsUpdated: undefined,
        errorMessages: action.payload,
        isValidating: false,
        isValid: action.payload && Object.keys(action.payload).length === 0,
      };

    case ACTION_SUBMITTING:
      return {
        ...state,
        _hooks: action.payload,
        isSubmitting: true,
        isSubmitted: false,
      };

    case ACTION_SUBMIT_SUCCESS:
      return {
        ...state,
        _fieldsUpdated: undefined,
        _hooks: null,
        isDirty: false,
        isSubmitting: false,
        isSubmitted: true,
      };

    case ACTION_SUBMIT_FAILED:
      return {
        ...state,
        _hooks: null,
        isSubmitting: false,
        isSubmitted: false,
      };

    default:
      return state;
  }
};

const parseErrors = (errors) => {
  if (errors instanceof Error) {
    return parseErrors(errors.message);
  }

  if (Array.isArray(errors)) {
    return errors.reduce(
      (acc, err) => ({
        ...acc,
        [err.path]: [...(acc[err.path] || []), ...err.errors],
      }),
      {},
    );
  }

  if (typeof errors === 'string') {
    return {
      _: [errors],
    };
  }

  return Object.entries(errors).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: value instanceof Object ? parseErrors(value) : i18n.t(`${value}`),
    };
  }, {});
};

const FormContext = createContext(null);

const useForm = ({
  initialValue = DEFAULT_INITIAL_VALUE,
  onSubmit,
  onCancel,

  schema,
  validateOnChange = DEFAULT_VALIDATE_ON_CHANGE,
}) => {
  const safeguard = useRef(0);

  const [formState, dispatch] = useReducer(formReducer, {
    ...formInitialState,
    value: initialValue,
  });

  const valueRef = useRef();
  valueRef.current = formState.value;

  const getValue = useCallback((field, defaultValue) => {
    const value = _fp.get(field, valueRef.current);
    // eslint-disable-next-line eqeqeq
    return value == undefined ? defaultValue : value;
  }, []);

  const setFormValue = useCallback((value) => {
    dispatch({ type: ACTION_RESET, payload: value });
  }, []);

  const setValue = useCallback((fieldOrObject, value) => {
    const changes =
      typeof fieldOrObject === 'string'
        ? { [fieldOrObject]: value }
        : fieldOrObject;

    dispatch({
      type: ACTION_SET,
      payload: changes,
    });
  }, []);

  const touch = useCallback((fields) => {
    dispatch({
      type: ACTION_TOUCH,
      payload: fields,
    });
  }, []);

  useEffect(() => {
    if (
      validateOnChange &&
      schema &&
      // eslint-disable-next-line no-underscore-dangle
      formState._fieldsUpdated &&
      // eslint-disable-next-line no-underscore-dangle
      formState._fieldsUpdated.length > 0
    ) {
      dispatch({
        type: ACTION_VALIDATING,
      });

      schema
        .validate(formState.value, {
          abortEarly: false,
          context: formState.value,
        })
        .then(() => {
          dispatch({
            type: ACTION_VALIDATED,
            payload: {},
          });
        })
        .catch((errors) => {
          const parsedErrors = parseErrors(errors.inner);
          const updatedFields = Array.isArray(formState._fieldsUpdated)
            ? formState._fieldsUpdated
            : [];

          const newErrorMessages = updatedFields.reduce((acc, field) => {
            const { [field]: omitted, ...rest } = acc;
            return parsedErrors && parsedErrors[field]
              ? {
                  ...rest,
                  [field]: parsedErrors[field],
                }
              : rest;
          }, formState.errorMessages || []);

          dispatch({
            type: ACTION_VALIDATED,
            payload: newErrorMessages,
          });
        });
    }
  }, [
    schema,
    validateOnChange,
    formState.value,
    // eslint-disable-next-line no-underscore-dangle
    formState._fieldsUpdated,
    formState.errorMessages,
  ]);

  useEffect(() => {
    if (formState.isSubmitting && safeguard.current > 0) {
      safeguard.current = 0;

      dispatch({
        type: ACTION_VALIDATING,
      });
      (schema
        ? schema.validate(formState.value, {
            abortEarly: false,
            context: formState.value,
          })
        : Promise.resolve(null)
      )
        .then(() => {
          dispatch({
            type: ACTION_VALIDATED,
            payload: {},
          });

          const {
            preSubmit,
            postSubmit,
            onSubmit: customOnSubmit,
          } = formState._hooks;

          return (
            Array.isArray(preSubmit) && preSubmit.length > 0
              ? preSubmit.reduce(
                  (acc, hook) => acc.then(hook),
                  Promise.resolve(null),
                )
              : Promise.resolve(null)
          )
            .then(() => {
              if (customOnSubmit && typeof customOnSubmit === 'function') {
                return customOnSubmit(formState.value);
              }

              if (typeof onSubmit === 'function') {
                return onSubmit(formState.value);
              }

              return Promise.resolve(null);
            })
            .then((submitResult) => {
              return (
                Array.isArray(postSubmit) && postSubmit.length > 0
                  ? postSubmit.reduce(
                      (acc, hook) => acc.then(hook),
                      Promise.resolve(null),
                    )
                  : Promise.resolve(null)
              )
                .catch(() => Promise.resolve(submitResult))
                .then(() => submitResult);
            });
        })
        .then(() => {
          dispatch({
            type: ACTION_SUBMIT_SUCCESS,
          });
        })
        .catch((errors) => {
          const parsedErrors = parseErrors(errors?.inner);
          dispatch({
            type: ACTION_VALIDATED,
            payload: parsedErrors,
          });
          dispatch({
            type: ACTION_SUBMIT_FAILED,
          });
        });
    }
    return () => undefined;
  }, [
    formState._hooks,
    formState.isSubmitting,
    formState.value,
    onSubmit,
    schema,
  ]);

  const handleChange = useCallback(
    (evtOrVal, event) => {
      if (evtOrVal && typeof evtOrVal.preventDefault === 'function') {
        const { name, type, value, checked } = evtOrVal.target;
        if (name == null || name === '') {
          return;
        }
        setValue(name, type === 'checkbox' ? checked : value);
      } else {
        const { name } = event.currentTarget;
        setValue(name, evtOrVal);
      }
    },
    [setValue],
  );

  const handleSubmit = useCallback(
    (evtOrOptions, options) => {
      let currentOptions = options;

      if (evtOrOptions && typeof evtOrOptions.preventDefault === 'function') {
        evtOrOptions.preventDefault();
      } else {
        currentOptions = evtOrOptions;
      }

      const {
        preSubmit,
        postSubmit,
        onSubmit: customOnSubmit,
      } = currentOptions || {};

      dispatch({
        type: ACTION_SUBMITTING,
        payload: { preSubmit, postSubmit, onSubmit: customOnSubmit },
      });

      safeguard.current = 1;

      dispatch({
        type: ACTION_VALIDATING,
      });

      (schema
        ? schema.validate(formState.value, {
            abortEarly: false,
            context: formState.value,
          })
        : Promise.resolve(null)
      )
        .then(() => {
          dispatch({
            type: ACTION_VALIDATED,
            payload: {},
          });
        })
        .catch((errors) => {
          const parsedErrors = parseErrors(errors.inner);
          dispatch({
            type: ACTION_VALIDATED,
            payload: parsedErrors,
          });
        });
    },
    [formState.value, schema],
  );

  const handleCancel = useCallback(
    (evt) => {
      if (evt && typeof evt.preventDefault === 'function') {
        evt.preventDefault();
      }

      if (typeof onCancel === 'function') {
        onCancel();
      }
    },
    [onCancel],
  );

  const controls = useMemo(
    () => ({
      input: (name, type = 'text') => ({
        name,
        type,
        value: getValue(name, ''),
        onChange: handleChange,
        invalid: formState.errorMessages?.[name],
      }),

      textarea: (name) => ({
        name,
        value: getValue(name, ''),
        onChange: handleChange,
        invalid: formState.errorMessages?.[name],
      }),

      checkbox: (name) => ({
        name,
        type: 'checkbox',
        value: getValue(name, false),
        checked: getValue(name, false),
        onChange: handleChange,
        invalid: formState.errorMessages?.[name],
      }),

      select: (name) => ({
        name,
        value: getValue(name, undefined),
        onChange: (v1) => setValue(name, v1),
        // onSelect: (val) => setValue(name, val),
        invalid: formState.errorMessages?.[name],
      }),

      custom: (name) => ({
        name,
        getValue,
        setValue,
        invalid: formState.errorMessages?.[name],
      }),
    }),
    [formState.errorMessages, getValue, handleChange, setValue],
  );

  const { _fieldsUpdated, ...exportedFields } = formState;

  return {
    ...exportedFields,

    setFormValue,
    setValue,
    touch,
    handleSubmit,
    handleCancel,

    ...controls,
  };
};

export { FormContext };

export default useForm;
