export const PosTypes = {
  content: [
    { id: 'POS' },
    { id: 'TERMINAL' },
    { id: 'OPERATOR' },
    { id: 'PORTAL' },
    { id: 'PORTAL_PJ' },
    { id: 'TVM_EXTERN' },
    { id: 'MOBILE' },
    { id: 'ENROLLMENT' },
  ],
  loading: false,
  error: false,
};

export const SalesChannels = {
  content: [
    { id: 'BACKOFFICE' },
    { id: 'PORTAL' },
    { id: 'MOBILE' },
    { id: 'TVM' },
    { id: 'POS' },
  ],
  loading: false,
  error: false,
};
