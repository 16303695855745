import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import { FormContext } from '../../../hooks/useForm';
import Column from '../../../helpers/columns';
import { getHolidays } from '../../../services/taxonomies/holidays';
import useDatasource from '../../../hooks/useDatasource';

const HolidaysSelection = () => {
  const form = useContext(FormContext);
  const { t } = useTranslation();

  const { value, setValue } = form;

  const columns = useMemo(
    () => [
      Column.text('id', 'ID', {
        width: 50,
      }),
      Column.text('name', t('entity.taxonomy.holiday.name'), {
        filter: true,
      }),
    ],
    [t],
  );

  const rowSelection = useMemo(
    () => ({
      hideSelectAll: true,
      onSelect: (record, selected) =>
        selected
          ? setValue('holidays', [...value.holidays, record.id])
          : setValue(
              'holidays',
              value.holidays.filter((id) => id !== record.id),
            ),
      selectedRowKeys: value.holidays || [],
      getCheckboxProps: ({ deleted, id }) => ({
        disabled:
          (deleted &&
            (value.holidays?.length > 0
              ? value.holidays?.indexOf(id) === -1
              : true)) ||
          value.deleted,
      }),
    }),
    [setValue, value.holidays, value.deleted],
  );

  const { pagination, content, handleChange } = useDatasource(getHolidays);

  return (
    <Table
      rowSelection={{
        type: 'checkbox',
        ...rowSelection,
      }}
      rowKey="id"
      columns={columns}
      dataSource={content}
      // loading={loading}
      pagination={pagination}
      onChange={handleChange}
    />
  );
};

export default HolidaysSelection;
