import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Divider,
  message,
  Radio,
  Switch,
  notification,
} from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import moment from 'moment';

import { useTranslation } from 'react-i18next';
import POSContext from '../POSContext';
import useDictionaries from '../../../hooks/useDictionaries';
// import useDefaultValues from '../../../hooks/useDefaultValues';

import {
  getUserTypesByIds,
  getUserTypes,
} from '../../../services/taxonomies/user-types';
import { getPosDocTypes } from '../../../services/taxonomies/doc-types';
import {
  cancelOrder,
  getAvailableLayouts,
  setOrderAccount,
} from '../../../services/pos/pos';
import {
  SERVICE_URI as FILE_SERVICE_URI,
  BASE_URI as FILE_BASE_URI,
} from '../../../services/files';

import Span from '../Span';
import POSButton from '../POSButton';
import WebCamImage from '../WebCamImage';
import { getInstitutions } from '../../../services/taxonomies/institutions';
import { getSubdivisions } from '../../../services/taxonomies/subdivisions';
import useFormErrors from '../../../hooks/useFormErrors';
import DatePickerLocale from '../../DatePickerLocale';
import UserTypesFields from '../UserTypesFields';
import PhoneInput from '../components/PhoneInput';

const getDictionaries = (ids) => ({
  userTypes: ids && ids.length > 0 ? getUserTypesByIds(ids) : getUserTypes,
  docTypes: getPosDocTypes,
});

const populateInstitutions = (userTypeId, userTypes, setInstitutions) => {
  const insts =
    userTypes.find((ut) => ut.id === userTypeId)?.institutions || [];
  if (insts.length) {
    getInstitutions({ pageSize: 100, criterias: { ids: insts } }).then(
      (res) => {
        setInstitutions(res.content);
      },
    );
  } else {
    setInstitutions([]);
  }
};

const populateSubdivisions = (institutionId, institutions, setSubdivisions) => {
  const subdivisions =
    institutions.find((i) => i.id === institutionId)?.subdivisions || [];
  if (subdivisions.length) {
    getSubdivisions({ pageSize: 100, criterias: { ids: subdivisions } }).then(
      (res) => {
        setSubdivisions(res.content);
      },
    );
  } else {
    setSubdivisions([]);
  }
};

const CustomizeCard = () => {
  const { t } = useTranslation();
  const { shift, order, setOrder } = useContext(POSContext);
  const dictionaries = useMemo(
    () =>
      getDictionaries([
        ...new Set(
          (order.products || [])
            .flatMap((item) => item.payload)
            .map((item) => item.userTypeId)
            .filter((item) => item),
        ),
      ]),
    [order],
  );

  const [{ userTypes, docTypes }] = useDictionaries(dictionaries);

  const { account } = order || {};
  const { card } = order || {};
  const { userProfileTypeId, institutionId, subdivisionId } = account || {};
  const { cardPhysicalId } = card || null;
  const isExternalCard =
    cardPhysicalId !== undefined && cardPhysicalId !== null;

  const [form] = Form.useForm();
  const [errors, setErrors] = useState(null);
  useFormErrors(form, errors);

  const [layouts, setLayouts] = useState([]);
  const [institutions, setInstitutions] = useState([]);

  const [subdivisions, setSubdivisions] = useState([]);

  useEffect(() => {
    if (userProfileTypeId) {
      populateInstitutions(
        userProfileTypeId,
        userTypes.content,
        setInstitutions,
      );
    }
  }, [userTypes.content, userProfileTypeId]);

  useEffect(() => {
    if (!isExternalCard) {
      getAvailableLayouts(shift, order)
        .then((c) => {
          const updatedLayouts = c.map((layout) => ({
            ...layout,
            disabled: false,
          }));
          setLayouts(updatedLayouts);
        })
        .catch((err) => {
          console.error(err);
          setLayouts([]);
        });
    }
  }, [shift, order, isExternalCard]);

  useEffect(() => {
    form.setFieldsValue({ layout: null });
    const notDisabledLayers = layouts.filter(
      (layout) => layout.disabled === false,
    );
    if (notDisabledLayers.length > 0) {
      const layoutId = notDisabledLayers.filter((_, idx) => idx === 0)[0]
        ?.fileId;
      form.setFieldsValue({ layout: layoutId });
    }
  }, [layouts, form]);

  useEffect(() => {
    const type = !userTypes.loading
      ? // eslint-disable-next-line eqeqeq
        userTypes.content.find((c) => c.id == userProfileTypeId)
      : {};
    const docs = type?.docTypes || [];

    const res = !docTypes.loading
      ? docTypes.content.filter((dt) => docs.includes(dt.id))
      : [];
    form.setFieldsValue({
      name: order.account?.userFullname?.trimStart().toUpperCase(),
      userTypeId: order.account?.userProfileTypeId,
      metaData1: order.account?.metaData1,
      metaData2: order.account?.metaData2,
      identityCard: order.account?.userIdentityCard,
      email: order.account?.userEmail,
      phoneNumber: order.account?.userPhone,
      address: order.account?.userAddress,
      files: res
        .filter((r) => !r.deleted)
        .map((r) => ({
          hasExpirationDate: r.hasExpirationDate,
          dateChangeable: r.dateChangeable,
          durationType: r.durationType,
          expirationDate: r.expirationDate ? moment(r.expirationDate) : null,
          docTypeId: r.id,
          fileId: null,
          docTypeName: r.name,
          verified: false,
        })),
    });
  }, [form, order, userTypes, docTypes, userProfileTypeId]);

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current < moment().add(-1, 'day');
  };

  useEffect(() => {
    form.setFieldsValue({
      institutionId,
      subdivisionId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (institutionId) {
      populateSubdivisions(institutionId, institutions, setSubdivisions);
    }
  }, [form, institutionId, institutions, setSubdivisions]);

  const handleChangeUserTypeId = useCallback(
    (value) => {
      // eslint-disable-next-line eqeqeq
      const type = userTypes.content.find((c) => c.id == value);
      const docs = type?.docTypes || [];

      if (value) {
        setSubdivisions([]);
        populateInstitutions(value, userTypes.content, setInstitutions);
      }

      const res = docTypes.content.filter((dt) => docs.includes(dt.id));
      form.setFieldsValue({
        institutionId: null,
        subdivisionId: null,
        metaData1: null,
        files: res
          .filter((r) => !r.deleted)
          .map((r) => ({
            hasExpirationDate: r.hasExpirationDate,
            dateChangeable: r.dateChangeable,
            durationType: r.durationType,
            docTypeId: r.id,
            fileId: null,
            docTypeName: r.name,
            expirationDate: r.expirationDate ? moment(r.expirationDate) : null,
            verified: false,
          })),
      });
    },
    [docTypes.content, userTypes.content, form],
  );

  const handleChangeInstitution = useCallback(
    (val) => {
      form.resetFields(['subdivisionId']);
      setSubdivisions([]);
      populateSubdivisions(val, institutions, setSubdivisions);
    },
    [institutions, form],
  );

  const handleCancelOrder = useCallback(() => {
    cancelOrder(shift, order)
      .then((res) => {
        setOrder(res);
      })
      .catch((err) => {
        console.error(err);
        message.error({
          content: 'Eroare la anulare comanda',
          key: 'pos',
          duration: 1,
          className: 'card-message',
        });
      });
  }, [shift, order, setOrder]);

  const handleOk = useCallback(() => {
    form.setFieldValue('isExternalCard', isExternalCard);
    form
      .validateFields()
      .then((value) => setOrderAccount(shift, order, value))
      .then((res) => setOrder(res))
      .catch((err) => {
        setErrors({});
        console.error(err);
        message.error({
          content: err?.inner?._ ? t(err.inner._) : 'Contul nu poate fi creat',
          key: 'pos',
          duration: 1,
          className: 'card-message',
        });
        setErrors({
          ...err.inner,
          ...(err.errorFields || [])?.reduce(
            (acc, ve) => ({ ...acc, [ve.name]: ve.errors }),
            {},
          ),
        });
      });
  }, [form, shift, order, setOrder, t, isExternalCard]);

  return (
    <div className="pos">
      <div className="pos__wrapper">
        <Form form={form} layout="vertical">
          <Form.Item hidden name={['isExternalCard']} />
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
            <Col span={10}>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
                <Col span={12}>
                  <Form.Item
                    name="name"
                    label="Nume, Prenume"
                    normalize={(val) => val.toUpperCase()}
                    rules={[
                      {
                        required: true,

                        message: t('errors.notEmpty'),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item name="userTypeId" label="Tip utilizator">
                    <Select
                      // loading={userTypes.loading}
                      onChange={handleChangeUserTypeId}
                    >
                      {userTypes.content.map((option) => (
                        <Select.Option
                          value={option.id}
                          key={option.id}
                          disabled={option.disabled || option.deleted}
                        >
                          {option.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name="institutionId" label="Institutia">
                    <Select
                      onChange={handleChangeInstitution}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {institutions.map((i) => (
                        <Select.Option
                          key={i.id}
                          value={i.id}
                          disabled={i.disabled || i.deleted}
                        >
                          {i.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <UserTypesFields
                    subdivisions={subdivisions}
                    showColumns={
                      userTypes?.content.find(
                        (ut) => ut.id === form.getFieldValue(['userTypeId']),
                      )?.showColumns
                    }
                  />
                </Col>
                <Col span={12}>
                  <Form.Item name="foreignCitizen" label="Cetatean strain">
                    <Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                    />
                  </Form.Item>
                  <Form.Item
                    name="identityCard"
                    label="CNP"
                    rules={[
                      {
                        required: true,
                        message: t('errors.notEmpty'),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                      {
                        type: 'email',
                        message: t('errors.incorrectField'),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="phoneNumber"
                    label="Telefon"
                    rules={[
                      {
                        required: true,
                        message: t('errors.notEmpty'),
                      },
                    ]}
                  >
                    <PhoneInput />
                  </Form.Item>
                  <Form.Item name="address" label="Adresa">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Form.List name="files" dependencies={['files']}>
                {(fields) => (
                  <div>
                    {fields.map((field) => (
                      <Row
                        gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}
                        key={field.key}
                      >
                        <Col span={3}>
                          <Form.Item
                            name={[field.name, 'docTypeName']}
                            fieldKey={[field.fieldKey, 'docTypeName']}
                          >
                            <Span />
                          </Form.Item>
                        </Col>
                        {!form.getFieldValue([
                          'files',
                          field.name,
                          'hasExpirationDate',
                        ]) && <Col span={10} />}

                        {form.getFieldValue([
                          'files',
                          field.name,
                          'hasExpirationDate',
                        ]) && (
                          <Col span={10}>
                            <Form.Item
                              {...field}
                              name={[field.name, 'expirationDate']}
                              label="Data Expirare"
                              fieldKey={[field.fieldKey, 'expirationDate']}
                            >
                              <DatePickerLocale
                                disabled={
                                  form.getFieldValue([
                                    'files',
                                    field.name,
                                    'durationType',
                                  ]) &&
                                  !form.getFieldValue([
                                    'files',
                                    field.name,
                                    'dateChangeable',
                                  ])
                                }
                                format="DD.MM.YYYY"
                                style={{ width: '100%' }}
                                disabledDate={disabledDate}
                              />
                            </Form.Item>
                          </Col>
                        )}
                        <Col span={8}>
                          <Form.Item
                            {...field}
                            name={[field.name, 'verified']}
                            fieldKey={[field.fieldKey, 'verified']}
                            label="Am verificat actul"
                          >
                            <Switch
                              checkedChildren={<CheckOutlined />}
                              unCheckedChildren={<CloseOutlined />}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    ))}
                  </div>
                )}
              </Form.List>
            </Col>
            {!isExternalCard && (
              <>
                <Col span={4}>
                  <Form.Item name="photo" label="Poza">
                    <WebCamImage layout="default" />
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Divider>Layout</Divider>

                  <Form.Item
                    name="layout"
                    label="layout"
                    rules={[
                      {
                        required: !isExternalCard,
                        message: t('errors.notEmpty'),
                      },
                    ]}
                  >
                    <Radio.Group>
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }}>
                        {layouts.map((layout) => (
                          <Col
                            span={8}
                            key={layout.cardId}
                            style={{ textAlign: 'center' }}
                          >
                            <Radio
                              value={layout.fileId}
                              disabled={layout.disabled}
                            >
                              <img
                                style={{
                                  border: '1px dotted lightgray',
                                  margin: '10px',
                                }}
                                src={`${window._env_.API_BACKEND_URL}${FILE_SERVICE_URI}${FILE_BASE_URI}/${layout.fileId}?download`}
                                width="100%"
                                height="120rem"
                                alt=""
                                onError={() => {
                                  const updatedLayouts = layouts.map((l) =>
                                    l.fileId === layout.fileId
                                      ? { ...l, disabled: true }
                                      : l,
                                  );
                                  setLayouts(updatedLayouts);
                                  notification.error({
                                    message:
                                      'Pentru unul din sabloanele de card nu există fișierul-imagine asociat. Vă rugăm contactați administratorul aplicației!',
                                  });
                                }}
                              />
                            </Radio>
                          </Col>
                        ))}
                      </Row>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </Form>
      </div>

      <div className="pos__actions">
        <div>
          <POSButton
            title="Anulare"
            color="red"
            size="small"
            onClick={handleCancelOrder}
          />
        </div>

        <div>
          <POSButton
            title="Mai departe"
            color="green"
            size="small"
            onClick={handleOk}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomizeCard;
